const RANGE_RESTRICTIONS: any = {
    temperature_min: { min: -20, max: 70 },
    temperature_max: { min: -20, max: 70 },
    mode_static: { min: 5, max: 43200 },
    mode_motion: { min: 5, max: 43200 },
    arrival_meters: { min: 100, max: 20000 },
    collision_acceleration: { min: 0, max: 8000 },
    battery_percentage: { min: 0, max: 100 },
    idle_distance: { min: 50, max: 50000 },
    idle_time: { min: 5, max: 1440 }
};

const UNITS_PER_FIELD: any = {
    triggers: {
        arrival: { meters: 'm' },
        battery: { percentage: '%' },
        idle: { distance: 'm', time: 'min' },
        temperature: { max: 'ºC', min: 'ºC'},
        collision: { acceleration: 'mg' }
    },
    tracking: { params: { static: 'min', motion: 'min' } }
};

const VALIDATORS: any = {
    inRange(value: any, min: number, max: number) {
        return (isEmptyValue(value) || min > value || value > max);
    },
    biggerThan(value: any, limit: number) {
        return (isEmptyValue(value) || isEmptyValue(limit) || value > limit);
    }
};

const isEmptyValue = (value: any) => (!value && value !== 0);

// const validateRanges = (status: any, fieldsToValidate: any) => {
//     Object.keys(fieldsToValidate).forEach(restriction => {
//         const { min, max } = RANGE_RESTRICTIONS[restriction];

//         if(VALIDATORS.inRange(fieldsToValidate[restriction], min, max)) {
//             status.errors[restriction] = `Min: ${min} / Max: ${max}`;
//         }
//     });
// };

const validateRanges = (status: any, fieldsToValidate: any) => {
    Object.keys(fieldsToValidate).forEach(restriction => {
        const { min, max } = RANGE_RESTRICTIONS[restriction];
        const value = fieldsToValidate[restriction];

        // Check if the value is present before validating
        if (value !== undefined && value !== null) {
            if(VALIDATORS.inRange(value, min, max)) {
                status.errors[restriction] = `Min: ${min} / Max: ${max}`;
            }
        }
    });
};

const validateSegments = (status: any, { temperature_min, temperature_max }: any) => {
    if((temperature_min || temperature_max) && VALIDATORS.biggerThan(temperature_min, temperature_max)) {
        status.errors.temperature_min = (status.errors.temperature_min || '') + '\nMinimum temperature can not be bigger than maximum';
        status.errors.temperature_max = (status.errors.temperature_max || '') + '\nMaximum temperature can not be smaller than minimum';
    }
};

const processValuesForValidation = (settings: any) => {
    const values: any = {};

    if(settings) {
        if(settings.triggers) {
            if(settings.triggers.temperature) {
                values.temperature_min = settings.triggers.temperature.min;
                values.temperature_max = settings.triggers.temperature.max;
            }
            if(settings.triggers.idle) {
                values.idle_distance = settings.triggers.idle.distance;
                values.idle_time = settings.triggers.idle.time;
            }
            if(settings.triggers.battery) values.battery_percentage = settings.triggers.battery.percentage;
            if(settings.triggers.arrival) values.arrival_meters = settings.triggers.arrival.meters;
            if(settings.triggers.collision && settings.tracking && settings.tracking.collision) values.collision_acceleration = settings.tracking.collision.threshold;
        }

        if(settings.tracking) {
            values.mode_motion = settings.tracking.params.motion;
            values.mode_static = settings.tracking.params.static;
        }
    }

    return values;
};

export const validateSettings = (settings: any) => {
    const status: { valid: boolean; errors: any } = { valid: true, errors: {} };
    const fieldsToValidate: any = processValuesForValidation(settings);

    validateRanges(status, fieldsToValidate);
    validateSegments(status, fieldsToValidate);

    status.valid = !Object.keys(status.errors).length;

    return status;
};

export const getFieldUnit = (path: string) =>
    path.split('.')
        .reduce((acc: any, curr: string) => acc[curr], UNITS_PER_FIELD);

export const getSettingValue = (path: string, settings: any) =>
    path.split('.')
        .reduce((acc: any, curr: string) => acc[curr], settings);

export const updateSettingValue = (path: string, setting: string, value: any, settings: any) => {
    return path.split('.')
        .reduce((acc: any, curr: string, index: number, array: string[]) => {
            if(array.length - 1 === index) {
                acc[curr][setting] = value;

                return true;
            }

            return acc[curr];
        }, settings);
};