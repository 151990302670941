import React, { Component } from 'react';
import TagsList from 'components/Tags/List';
import { calculateLastUpdated } from 'utils/date';
import { getNamedEshell } from 'utils/tracker';
import './styles.scss';

interface Props {
    tracker: any;
}

export default class Tracker extends Component<Props> {
    render() {
        const tracker = this.props.tracker;
        const lastMeas = tracker.lastMeasurement;

        return (<div className="tracker-info">
            <h2 className="tracker-name">{tracker.name}</h2>

            <span className="tracker-container">
                <b>Model:</b>
                { getNamedEshell(tracker.model) }
            </span>

            <span className="tracker-container">
                <b>Identifier:</b>
                { tracker.identifier }
            </span>

            {
                tracker.container && (
                    <span className="tracker-container">
                        <b>Asset Id:</b>
                        { tracker.container }
                    </span>
                )
            }

            {
                tracker.events && (
                    <span className="tracker-events">
                        <b>Events:</b>
                        { this.props.tracker.events.length }
                    </span>
                )
            }

            {
                lastMeas && (
                    <>
                        {
                            (!!lastMeas.battery) && <span className="tracker-battery">
                                <b>Battery:</b>
                                { lastMeas.battery }%
                            </span>
                        }
                        {
                            (!!lastMeas.temperature) && <span className="tracker-temperature">
                                <b>Temp:</b>
                                {lastMeas.temperature}ºC
                            </span>
                        }
                        <span className="tracker-updated">
                            <b>Updated:</b>
                            { calculateLastUpdated(lastMeas.time)}
                        </span>
                    </>
                )
            }

            { this.props.tracker.tags.length > 0 && (
                <div>
                    <span className="tracker-tags"><b>Tags: </b></span>
                    { <TagsList tags={this.props.tracker.tags} />}
                </div>
                )
            }
        </div>);
    }
}
