import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Button from 'components/Inputs/Button';
import Input from 'components/Inputs/Input/Default';
import FormSettings from 'components/Forms/Settings';
import { validateSettings } from 'components/Forms/Settings/utils';
import Icon from 'components/Icon';
import { TagsContext } from '../../context';
import './styles.scss';
import NewFormSettings from 'components/Forms/Settings/indexx';

interface Props extends RouteComponentProps {
    tag: any;
    update: any;
    delete: any;
}

interface State {
    loading: boolean;
    update: any;
}

class ConfigurationTag extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            update: JSON.parse(JSON.stringify(this.props.tag))
        };
    }

    inputChange = (name: string, value: string, sublevel?: string) => {
        const update =  Object.assign({}, this.state.update);

        if(sublevel) update[sublevel][name] = value;
        else update[name] = value;

        this.setState({ update });
    }

    canSave = (valid: boolean) => {
        const update = this.state.update,
            current = this.props.tag;

        return update.name && update.styles.color && valid &&
            (current.name !== update.name ||
            current.styles.color !== update.styles.color ||
            JSON.stringify(current.settings) !== JSON.stringify(update.settings));
    }

    render() {
        const settings = this.state.update.settings,
            validation = validateSettings(settings);

        return (<div className="tag-configuration">
            <div className='tag-configuration-content'>
                <div className='tag-configuration-group'>
                    <h3 className='tag-configuration-title'>Fields</h3>
                    <Input type="text" name="name" value={this.state.update.name}
                            label="Name:"
                            onChange={(event: any) => this.inputChange("name", event.target.value)}/>

                    <div className='tag-configuration-color'>
                        <Input className='tag-configuration-input'
                                type="color" name="color" value={this.state.update.styles.color}
                                label="Color:"
                                onChange={(event: any) => this.inputChange("color", event.target.value, "styles")}/>
                        <Icon size={20} name="edit" className='tag-configuration-icon'/>
                    </div>
                </div>
                <div className='tag-configuration-group'>
                    <h3 className='tag-configuration-title'>Settings</h3>
                    <NewFormSettings className='bc-stretch'
                        data={{
                            readOnly: false,
                            settings,
                            validation
                        }}
                        update={(settings: any) => this.inputChange("settings", settings)} />
                </div>
            </div>
            <div className='tag-configuration-actions'>
                <Button
                    className="conf-save"
                    category="Tags"
                    action="Save"
                    icon="save"
                    disabled={!this.canSave(validation.valid)}
                    label={this.state.update.name}
                    onClick={() => this.props.update(this.state.update)}
                >
                    Save
                </Button>

                <Button
                    className="conf-delete"
                    category="Tags"
                    action="Delete"
                    icon="close"
                    label={this.props.tag.name}
                    onClick={() => this.props.delete() }
                >
                    Delete
                </Button>
            </div>
        </div>);
    }
}

ConfigurationTag.contextType = TagsContext;

export default withRouter(ConfigurationTag);