import React, { Component } from "react";
import FormSettings from "components/Forms/Settings";

interface Props {
  shipment: any;
  trackers: any[];
  validation: any;
  onChange: any;
}

export default class ShipmentSettings extends Component<Props> {
  updateSettings = (settings: any) => {
    this.props.onChange("settings", settings);
  };

  render() {
    // Function to extract settings from trackers based on the model
    function getSettingsForModel(trackers: any, model: any) {
      // Find the tracker with the specified model
      const tracker = trackers.find((t: any) => t.model === model);

      // Return settings if the model is found, otherwise return null or default settings
      return tracker ? tracker.settings : null;
    }

    // Usage example
    const formData = {
      trackers: this.props.trackers,
      settings: this.props.shipment.settings,
      validation: this.props.validation,
    };

    console.log(formData, "formdata");

    // Extract settings for model "MT07C"
    const mt07cSettings = getSettingsForModel(formData.trackers, "MT07C");

    // Optionally update the formData object with these settings
    if (mt07cSettings) {
      formData.settings = mt07cSettings;
    } else {
      console.log("No tracker with model MT07C found.");
    }
    const modelName =
      this.props.trackers &&
      this.props.trackers[0] &&
      this.props.trackers[0].model &&
      this.props.trackers[0].model;
 
      console.log(modelName,"model name ");

    return (
      <>

        <h4 className="new-box-title">Set configuration</h4>



          <div className="new-box-scroll">
          <FormSettings
            data={formData}
            update={this.updateSettings}
            model={modelName}
          />
        </div>
      </>
    );
  }
}
