import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import Page from 'components/ControlBars/Page';

import './styles.scss';

export default class Admin extends Component {
  renderWrapper = (title: string, desc: string, link: string) => {
    return (
        <Link className="admin-option" to={link}>
          <div className="admin-option-text">
            <h2>{title}</h2>
            <p>{desc}</p>
          </div>
          <ReactSVG className="admin-go-icon" src="/assets/svg/chevron-right.svg" />
        </Link>
    );
  }

  render() {
    return (
      <Page className="shlk-admin">
        <div className="admin-wrapper">
          <h1>Admin Pages</h1>

          <div className="admin-options">
            { this.renderWrapper("Free Trackers", "Assign free trackers to users", "/admin/trackers/free") }
            { this.renderWrapper("All Trackers", "Check the location of all the trackers", "/admin/trackers/all") }
            { this.renderWrapper("Users", "Manage Naxxar Tech users", "/admin/users") }
            { this.renderWrapper("New Company", "Create a new company", "/admin/company") }
            { this.renderWrapper("Data", "Check Naxxar Tech data", "/admin/data") }
            { this.renderWrapper("All Shipments", "Check all shipments", "/admin/shipments") }

          </div>
        </div>
      </Page>
    );
  }
}
