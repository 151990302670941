import React, { Component } from "react";
import Page from "components/ControlBars/Page";
import ShipmentCard from "components/Cards/Shipment";
import ContentLoader from "components/Loading/ContentLoader";
import ShipmentsFilter from "components/Filter/Shipments";
import { getShipments } from "services/shipments";
import { debounce, reducer } from "utils/functions";
import "./styles.scss";
import { ShipmentContext } from "components/Shipment/context";
import RoutesMap from "components/GoogleMap/RoutesMap";
import MapComponent from "components/GoogleMap/RouteMapv2";
import Shipment from "components/Shipment";
import AbstractMap from "components/GoogleMap/Abstract";
import LocationMarker from "components/GoogleMap/Markers/Location";
import GoogleMapsLoader from "google-maps";
import GoogleMapReact from "google-map-react";
import Icon from "components/Icon";
import TrackableElement from "components/Trackable/Element";
import { goBack } from "utils/navigation";
import { Link } from "react-router-dom";
import ShipmentCardV2 from "components/Cards/Shipment/dashboardcard";
import { ReactSVG } from "react-svg";
import ShipmentCardV3 from "components/Cards/Shipment/allshipmentcard";
import { isMobile } from "utils/device";

interface State {
  shipments: any;
  loading: boolean;
  isVisible: boolean; // Add isVisible state to track the visibility of the container
  isMobile: boolean;
  selectedShipment: { origin: any; destination: any } | null;
  activeTab: any;
}

export default class Shipments extends Component<{}, State, any> {
  private defaultFilter = { status: ["ongoing", "completed"], tags: [] };

  constructor(props: {}) {
    super(props);

    this.state = {
      shipments: [],
      loading: true,
      isVisible: true, // Initialize isVisible as true
      isMobile: false,
      selectedShipment: null,
      activeTab: "Ongoing",
    };
  }
  handleShipmentCardHover = (origin: any, destination: any) => {
    this.setState({ selectedShipment: { origin, destination } });
  };

  handleShipmentCardTouchStart = (origin: any, destination: any) => {
    // Simulate hover behavior for mobile devices
    this.setState({ selectedShipment: { origin, destination } });
  };

  componentDidMount() {
    getShipments(this.defaultFilter).then((shipments: any) =>
      this.setState({
        shipments,
        loading: false,
      })
    );
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };

  filterCallback = (filter: any) => {
    this.setState({ loading: true }, () => this.getFilteredShipments(filter));
  };

  getFilteredShipments = debounce((filter: any) => {
    getShipments(filter).then((shipments) =>
      this.setState({
        shipments,
        loading: false,
      })
    );
  }, 1200);
  // Method to handle close button click
  handleOpenButtonClick = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };
  // Method to handle close button click
  handleCloseButtonClick = () => {
    this.setState({ isVisible: false });
  };

  renderShipmentsByType = (type: string, shipments: any[]) =>
    shipments.length > 0 ? (
      <>
        <div
          className={`shlk-shipments__container ${type}`}
          style={{
            display: "grid",
            height: this.state.isMobile ? "60vh" : "",
            overflowY: this.state.isMobile ? "scroll" : "unset",
            padding: this.state.isMobile ? "5px" : "",
            alignContent:"flex-start"
          }}
        >
          {shipments.map((shipment: any) => (
            <ShipmentCardV3
              key={`Shipment_${shipment._id}`}
              shipment={shipment}
              lines={[]}
              showBoxButton={false}
              actions={false}
              onHover={this.handleShipmentCardHover}
              allShipmentPage={true}
              onClick={() => this.handleShipmentCardHover}
            />
          ))}
        </div>
      </>
    ) : (
      <span style={{ marginTop: "5px" }}>0 Shipments</span>
    );

  setActiveTab = (tab: any) => {
    this.setState({ activeTab: tab });
  };

  renderShipments = () => {
    const [ongoing, completed] = reducer(
      this.state.shipments,
      (shipment: any) => shipment.status === "ongoing"
    );

    return (
      <>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              background: "#e8eeef",
              alignItems: "center",
              height: "45px",
              marginBottom:"10px"
            }}
          >
            <div
              style={{
                // marginTop: this.state.isMobile ? "5px" : "",
                cursor: "pointer",
                // marginRight: "20px",
                borderBottom:
                  this.state.activeTab === "Ongoing"
                    ? "3px solid #19575c"
                    : "3px solid transparent",
                color:
                  this.state.activeTab === "Ongoing" ? "#19575c" : "#3d9aa1",
                // marginLeft: "10px",
                fontWeight:"bold",
                width: "100%",
                marginTop: "10px",
                height: "30px",
                textAlign:"center"
              }}
              onClick={() => this.setActiveTab("Ongoing")}
              onMouseOver={(e) => {
                e.currentTarget.style.color = "#19575c";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.color =
                  this.state.activeTab === "Ongoing" ? "#19575c" : "#3d9aa1";
              }}
            >
              Ongoing
            </div>
            <div
              style={{
                // marginTop: this.state.isMobile ? "5px" : "",
                cursor: "pointer",
                // marginRight: "20px",
                borderBottom:
                  this.state.activeTab === "Completed"
                    ? "3px solid #19575c"
                    : "3px solid transparent",
                color:
                  this.state.activeTab === "Completed" ? "#19575c" : "#3d9aa1",
                // marginLeft: "10px",
                fontWeight:"bold",
                width: "100%",
                marginTop: "10px",
                height: "30px",
                textAlign:"center"
              }}
              onClick={() => this.setActiveTab("Completed")}
              onMouseOver={(e) => {
                e.currentTarget.style.color = "#19575c";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.color =
                  this.state.activeTab === "Completed" ? "#19575c" : "#3d9aa1";
              }}
            >
              Completed
            </div>
          </div>
          {this.state.activeTab === "Ongoing" &&
            this.renderShipmentsByType("Ongoing", ongoing)}
          {this.state.activeTab === "Completed" &&
            this.renderShipmentsByType("Completed", completed)}
        </div>
      </>
    );
  };

  showResults = () => {
    return this.state.shipments.length ? (
      this.renderShipments()
    ) : (
      <h3>0 shipments found</h3>
    );
  };

  render() {
    const { isMobile } = this.state;
    const { selectedShipment } = this.state;
    console.log(this.state.shipments, "shipmenthiiiiiiiiiiiii");
    return (
      <Page className="shlk-page-shipments">
        {/* Render the container based on isVisible state */}
        {!isMobile && (
          <>
            <AbstractMap
              center={(() => {
                if (
                  selectedShipment &&
                  selectedShipment.origin &&
                  selectedShipment.destination
                ) {
                  // Calculate center point between origin and destination
                  const centerLat =
                    (selectedShipment.origin.location.lat +
                      selectedShipment.destination.location.lat) /
                    2;
                  const centerLng =
                    (selectedShipment.origin.location.lng +
                      selectedShipment.destination.location.lng) /
                    2;
                  return { lat: centerLat, lng: centerLng };
                } else if (selectedShipment && selectedShipment.origin) {
                  // Center on origin if only origin is present
                  return {
                    lat: selectedShipment.origin.location.lat,
                    lng: selectedShipment.origin.location.lng,
                  };
                } else {
                  // Default center if no points are available
                  return { lat: 0, lng: 0 };
                }
              })()}
              zoom={(() => {
                if (
                  selectedShipment &&
                  selectedShipment.origin &&
                  selectedShipment.destination
                ) {
                  // Calculate zoom level based on distance between origin and destination
                  const latDiff = Math.abs(
                    selectedShipment.origin.location.lat -
                      selectedShipment.destination.location.lat
                  );
                  const lngDiff = Math.abs(
                    selectedShipment.origin.location.lng -
                      selectedShipment.destination.location.lng
                  );
                  const maxDiff = Math.max(latDiff, lngDiff);
                  // Adjust the zoom level based on the maximum difference
                  return Math.floor(Math.log2(10 / maxDiff));
                } else {
                  // Default zoom level if only one point or no points are available
                  return 0;
                }
              })()}
              style={{ position: "static" }}
              origin={selectedShipment ? selectedShipment.origin : ""}
              destination={selectedShipment ? selectedShipment.destination : ""}
            >
              {/* Your markers, overlays, etc. */}
            </AbstractMap>
          </>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "-15px",
            marginTop: "-15px",
          }}
        >
          <div
            style={{
              background: "white",
              height: "100px",
              width: "30px",
              // marginTop: "5px",
              // marginLeft: "5px",
              boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
              padding: "15px",
              position: "relative",
              zIndex: isMobile ? "1" : "",
            }}
          >
            <Link to="/">
              <TrackableElement
                className="box-button back"
                category="Box"
                action="Back button"
              >
                <Icon
                  className={`box-button-back`}
                  size={26}
                  name="arrow_back"
                />
                <span className={`back`}>Back</span>
              </TrackableElement>
            </Link>
            <TrackableElement
              className="box-button"
              category="Box"
              action={"open"}
              onClick={this.handleOpenButtonClick}
            >
              {this.state.isVisible ? (
                <Icon className="box-button-menu" size={28} name={`place`} />
              ) : (
                <Icon className="box-button-menu" size={28} name={`menu`} />
              )}
            </TrackableElement>
          </div>
          {isMobile && (
            <>
              {" "}
              <AbstractMap
                center={(() => {
                  if (
                    selectedShipment &&
                    selectedShipment.origin &&
                    selectedShipment.destination
                  ) {
                    // Calculate center point between origin and destination
                    const centerLat =
                      (selectedShipment.origin.location.lat +
                        selectedShipment.destination.location.lat) /
                      2;
                    const centerLng =
                      (selectedShipment.origin.location.lng +
                        selectedShipment.destination.location.lng) /
                      2;
                    return { lat: centerLat, lng: centerLng };
                  } else if (selectedShipment && selectedShipment.origin) {
                    // Center on origin if only origin is present
                    return {
                      lat: selectedShipment.origin.location.lat,
                      lng: selectedShipment.origin.location.lng,
                    };
                  } else {
                    // Default center if no points are available
                    return { lat: 0, lng: 0 };
                  }
                })()}
                zoom={(() => {
                  if (
                    selectedShipment &&
                    selectedShipment.origin &&
                    selectedShipment.destination
                  ) {
                    // Calculate zoom level based on distance between origin and destination
                    const latDiff = Math.abs(
                      selectedShipment.origin.location.lat -
                        selectedShipment.destination.location.lat
                    );
                    const lngDiff = Math.abs(
                      selectedShipment.origin.location.lng -
                        selectedShipment.destination.location.lng
                    );
                    const maxDiff = Math.max(latDiff, lngDiff);
                    // Adjust the zoom level based on the maximum difference
                    return Math.floor(Math.log2(1 / maxDiff));
                  } else {
                    // Default zoom level if only one point or no points are available
                    return 0;
                  }
                })()}
                origin={selectedShipment ? selectedShipment.origin : ""}
                destination={
                  selectedShipment ? selectedShipment.destination : ""
                }
                style={{
                  position: "absolute",
                  top: this.state.isVisible ? "0px" : "0px",
                  width: "100%",
                  height: this.state.isVisible ? "30%" : " 100%",
                  right: "5px",
                }}
              >
                {/* Your markers, overlays, etc. */}
              </AbstractMap>
            </>
          )}
          {this.state.isVisible && (
            <>
              <div
                style={{
                  background: "white",
                  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
                  width: isMobile ? "90%" : "400px",
                  // height: : "90%",
                  // overflowY: "auto",
                  position: "absolute",
                  left: isMobile ? "0px" : "60px",
                  paddingLeft: isMobile ? "" : "10px",
                  borderRadius: isMobile ? "" : "",
                  marginTop: isMobile ? "0px" : "0px",
                  height: isMobile ? "75%" : "90%",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                  scrollMargin: "10px",
                  top: isMobile ? "30%" : "",
                  overflow: "scroll",
                  padding: isMobile ? "20px" : "10px",
                }}
              >
                {/* Close button */}
                <div
                  onClick={this.handleCloseButtonClick}
                  style={{
                    float: "right",
                    cursor: "pointer",
                    color: "#19575C",
                    fontSize: "20px",
                    marginRight: isMobile ? "" : "20px",
                    marginTop: isMobile ? "" : "20px",
                  }}
                >
                  <ReactSVG
                    style={{ color: "black" }}
                    src={"/assets/svg/close1.svg"}
                  />
                </div>

                <ShipmentsFilter
                  filter={this.defaultFilter}
                  filterCallback={(filter: any) => this.filterCallback(filter)}
                  allShipmentPage={true}
                />
                <ContentLoader
                  loading={this.state.loading}
                  content={this.showResults}
                />
              </div>
            </>
          )}
        </div>
      </Page>
    );
  }
}
