import React, { Component, FormEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import TrackableElement from 'components/Trackable/Element';
import { setCookieData } from 'utils/data/cookies';
import { setAnalyticsUserId } from 'utils/analytics';
import { login } from 'services/login';
import './styles.scss';

const REMEMBERME_EXPIRATION = 30 * 24 * 60 * 60 * 1000; // 30 days

type PropsType = RouteComponentProps<{}>

type State = {
  error: string;
  loading: boolean;
  form: {
    email: string;
    password: string;
    remember: boolean;
  };
};

class Login extends Component <PropsType, State> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      error: '',
      loading: false,
      form: {
        email: this.getFromQuery('email') || '',
        password: this.getFromQuery('password') || '',
        remember: false
      }
    };
  }

  getFromQuery = (key: string) => {
    const search = this.props.location.search.replace("?", "");

    const event = search.split("&").filter((query) => query.startsWith(key + "="));

    if (event.length > 0) {
      return decodeURIComponent(event[0].split("=")[1]);
    }

    return null;
  }

  handleInputChange = (name: string, value: boolean | string): void => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  handleSubmit = (e: FormEvent): void => {
    e.preventDefault();

    this.setState({
      ...this.state,
      loading: true,
      error: ''
    });

    const { email, password, remember } = this.state.form;
    const session = remember ? REMEMBERME_EXPIRATION : undefined;

    login({ email, password }, { forceUpdate : true, handledException: true })
      .then(({ userId, authToken }: { userId: string; authToken: string }) => {
        setAnalyticsUserId(userId);
        setCookieData('email', email, session);
        setCookieData('userId', userId, session);
        setCookieData('authToken', authToken, session);

        const from = this.getFromQuery('from');

        if(from) {
          this.props.history.push(from);
        } else {
          this.props.history.push('/');
        }
      }).catch(() => {
        this.setState({
          loading: false,
          error: 'Check your username and password'
        });
      });
  };

  renderError = () => {
    if (this.state.error !== '') {
      return (<div className='form-errors'>
        <p>{this.state.error}</p>
      </div>);
    }

    return '';
  };

  render() {
    return (
      <div className="shlk-login">
        <div className="shlk-login-wrapper">
          <img className="shlk-login-logo"
                src="/assets/icon/Naxxar_Full.png"
                alt="Naxxar" />
          <div>
            <div>
              <form className="shlk-login-form"
                    onSubmit={(e: React.FormEvent) => { this.handleSubmit(e); }}>

                <div className="shlk-form__container">
                  <input className="shlk-login-input shlk-login-email"
                            name="email"
                            type="text"
                            placeholder="Email Address"
                            defaultValue={this.state.form.email}
                            onInput={(e: React.FormEvent<HTMLInputElement>) => this.handleInputChange(e.currentTarget.name, e.currentTarget.value)} />

                  <input className="shlk-login-input shlk-login-pwd"
                            name="password"
                            type="password"
                            placeholder="Password"
                            defaultValue={this.state.form.password}
                            onInput={(e: React.FormEvent<HTMLInputElement>) => this.handleInputChange(e.currentTarget.name, e.currentTarget.value)} />
                </div>

                <label className="shlk-login-input shlk-login-remember">
                                  <input  name="remember"
                                          type="checkbox"
                                          data-testid="checkbox-remember"
                                          defaultChecked={false}
                                          onChange={(e: React.FormEvent<HTMLInputElement>) => this.handleInputChange(e.currentTarget.name, e.currentTarget.checked)} />
                  <span>Remember me</span>
                </label>

                {this.state.loading && (
                    <div className="shlk-login-input shlk-login-btn shlk-login__loading">
                      <ReactSVG className="shlk-loading-icon"
                                src="/assets/svg/refresh.svg" />
                    </div>
                )}

                {!this.state.loading && (
                  <TrackableElement
                    category="Login"
                    action="Click Login"
                    label={this.state.form.email}
                  >
                    <input className="shlk-login-input shlk-login-btn"
                          type="submit"
                          value="Login"/>
                  </TrackableElement>
                )}
              </form>
            </div>
            <div className="error-card alert-danger text-center align-middle">
              { this.state.error !== '' ? this.renderError() : '' }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
