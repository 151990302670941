import { shlkAPI, clean } from 'services/api';

export const createDocument = async (data: any) =>
            shlkAPI('document/create', data).then(clean);

export const deleteDocument = async (shipment = "", id: string) =>
            shlkAPI('document/delete', { shipment, id }).then(clean);

export const downloadDocument = async (id: string, name: string) => {
  return shlkAPI('document/download', { id }, true, { responseType : 'blob' })
          .then((data: any) => {
              const url = window.URL.createObjectURL(new Blob([data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', name);
              document.body.appendChild(link);
              link.click();
          });
};