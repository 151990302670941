import React, { CSSProperties, Component, PropsWithChildren } from 'react';
import Header from '../Header';
import './styles.scss';

type PageProps = PropsWithChildren<{
  className: string;
  type?: 'error' | 'map';
}>

export default class Page extends Component<PageProps> {
  render() {
    let className = 'shlk-page';
    const style: CSSProperties = {};

    if(this.props.type) className += ` shlk-page--${this.props.type}`;
    if(this.props.className) className += ` ${this.props.className}`;
    if(this.props.type === "error") style.backgroundImage = "url(assets/img/background_logo.png)";

    return (
      <div className={className} style={style}>
        <Header />

        <div className='shlk-page-content'>
          {this.props.children}
        </div>
      </div>
    );
  }
}