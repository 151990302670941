import React, { Component } from 'react';

import { trackEvent } from 'utils/analytics';

import './styles.scss';

interface Props {
    update: any;
    unit: string;
    name: string;
    value: string;
    error?: string;
    disabled?: boolean;
}

export default class InputSettings extends Component<Props> {
    static defaultProps = { value: '' };

    render() {
        return (<div className="settings-input">
            <label className="settings-input__name">{this.props.name} ({this.props.unit}):</label>
            <input
                className={`settings-input__field ${this.props.error ? 'invalid' : ''}`}
                type="number"
                value={this.props.value}
                disabled={this.props.disabled}
                onChange={({ currentTarget }) => {
                    const intValue = currentTarget.value.length ? parseInt(currentTarget.value) : undefined;

                    trackEvent('InputSettingsForm', this.props.name, currentTarget.value);
                    this.props.update(intValue);
                }}
            />
            {
                this.props.error &&
                    <div className="settings-input__validation">{this.props.error}</div>
            }
        </div>);
    }
}