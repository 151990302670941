import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from 'components/Inputs/Button';
import TrackableElement from 'components/Trackable/Element';
import Icon from 'components/Icon';
import BoxOption from 'components/Box/Option';
import { calculateLastUpdated } from 'utils/date';
import './styles.scss';

interface Props extends RouteComponentProps {
  tracker: any;
  onSelect: Function;
  click: any;
  opened: any;
  selected: boolean;
  openedRef: any;
}

class BoxTrackerTrackers extends Component<Props> {
  open = () =>
    this.props.click(this.props.opened ? null : this.props.tracker.identifier)

  onSelect = () => {
    this.props.onSelect(this.props.tracker);
  }

  renderTracker = (tracker: any) => {
    const shipment = tracker.transport && tracker.transport.shipment;
    const transportIcon = tracker.transport && (tracker.transport.status === 'ONBOARD' ? 'directions_boat' : 'local_shipping');

    return (<div className="boxtracker-info">
      <div className="tracker-title">
          { transportIcon && <Icon className="title-icon" name={transportIcon} size={14} type="outlined" /> }
          {tracker.name}
      </div>
      <div className="tracker-field">
      {
        tracker.lastMeasurement ?
          `${tracker.lastMeasurement.battery ? `${tracker.lastMeasurement.battery}%` : ''} Updated ${calculateLastUpdated(tracker.lastMeasurement.time)}` :
          'Waiting for first eShell emision'
      }
      </div>
      <div className="tracker-action">
        <Button category="eShells"
          action="Select eShell"
          styles="outline"
          className={`tracker-button selection ${this.props.selected ? 'selected' : 'unselected'}`}
          icon="done"
          iconSize={18}
          onClick={this.onSelect}
        />
        <Button category="eShells"
          action="Click View eShell"
          reference={this.props.opened ? this.props.openedRef : null}
          label={tracker.identifier}
          className="tracker-button"
          onClick={() => {
            this.open();
            this.props.history.push(`/trackers/${tracker.identifier}`);
          }}>
          <ReactSVG className="tracker-icon" src="/assets/svg/eShells.svg" />
        </Button>
      </div>
    </div>);
  }

  render() {
    const tracker = this.props.tracker,
          initial = !tracker.lastMeasurement,
          icon = initial ? 'refresh' : undefined;

    return <TrackableElement
              className="tracker-option"
              category="eShells"
              action="Click View eShell"
              onClick={!initial ? this.open : null}>
                <BoxOption
                  className={initial ? 'loading' : ''}
                  disabled={initial}
                  selected={this.props.opened}
                  icon={icon}
                >
                { this.renderTracker(tracker) }
              </BoxOption>
            </TrackableElement>;
  }
}

export default withRouter(BoxTrackerTrackers);