import React, { Component } from "react";
import "./styles.scss";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ShipmentContext } from "../context";
import { getShipmentInfo } from "services/shipments";
import Icon from "components/Icon";
import { loadDependencies } from "utils/charts";

interface MatchProps {
  id: string;
}

interface Props extends RouteComponentProps<MatchProps> {
  actions: boolean;
  shipment: any;
  lines: number[];
  showBoxButton: boolean;
  openShareTab: () => void; // New prop for opening the Share tab
  isPublic?: any;
}

interface State {
  loading: boolean;
  hoveredLineIndex: number | null;
  expanded: boolean;
  isMobile: boolean;
  currentSlide: number;
  emissionsTimeLine: any;
  ShipmentsData: any;
  emissionStats: any;
  door: any;
  inside: any;
  other: any;
  tracker: any;
  first: any;
  second: any;
  third: any;
  fourth: any;
  first1: any;
  second1: any;
  third1: any;
  fourth1: any;
  showDropdown: any;
  showDropdown1: any;
  showDropdown2: any;
}

class ScanBox extends Component<Props, State> {
  static contextType = ShipmentContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredLineIndex: null,
      loading: true,
      expanded: false,
      isMobile: false,
      currentSlide: 0,
      emissionsTimeLine: {},
      ShipmentsData: {},
      emissionStats: {},
      door: true,
      inside: false,
      other: false,
      tracker: false,
      first: true,
      second: false,
      third: false,
      fourth: false,
      first1: true,
      second1: false,
      third1: false,
      fourth1: false,
      showDropdown: false,
      showDropdown1: false,
      showDropdown2: false,
    };

    // Bind event handlers
    this.handleLineHover = this.handleLineHover.bind(this);
    this.handleLineLeave = this.handleLineLeave.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  handleLineHover(index: number) {
    this.setState({ hoveredLineIndex: index });
  }

  handleLineLeave() {
    this.setState({ hoveredLineIndex: null });
  }

  toggleExpanded() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  toggleDropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };
  toggleDropdown1 = () => {
    this.setState({ showDropdown1: !this.state.showDropdown1 });
  };
  toggleDropdown2 = () => {
    this.setState({ showDropdown2: !this.state.showDropdown2 });
  };
  doorClick() {
    this.setState((prevState) => ({
      door: true,
      inside: false,
      other: false,
      tracker: false,
    }));
  }

  insideClick() {
    this.setState((prevState) => ({
      inside: true,
      other: false,
      door: false,
      tracker: false,
    }));
  }

  otherClick() {
    this.setState((prevState) => ({
      other: true,
      door: false,
      inside: false,
      tracker: false,
    }));
  }

  trackerClick() {
    this.setState((prevState) => ({
      tracker: true,
      other: false,
      door: false,
      inside: false,
    }));
  }

  firstClick() {
    this.setState((prevState) => ({
      first: true,
      second: false,
      third: false,
      fourth: false,
    }));
  }

  secondClick() {
    this.setState((prevState) => ({
      second: true,
      first: false,
      third: false,
      fourth: false,
    }));
  }

  thirdClick() {
    this.setState((prevState) => ({
      third: true,
      first: false,
      second: false,
      fourth: false,
    }));
  }

  fourthClick() {
    this.setState((prevState) => ({
      fourth: true,
      first: false,
      second: false,
      third: false,
    }));
  }

  firstClick1() {
    this.setState((prevState) => ({
      first1: true,
      second1: false,
      third1: false,
      fourth1: false,
    }));
  }

  secondClick1() {
    this.setState((prevState) => ({
      second1: true,
      first1: false,
      third1: false,
      fourth1: false,
    }));
  }

  thirdClick1() {
    this.setState((prevState) => ({
      third1: true,
      first1: false,
      second1: false,
      fourth1: false,
    }));
  }

  fourthClick1() {
    this.setState((prevState) => ({
      fourth1: true,
      first1: false,
      second1: false,
      third1: false,
    }));
  }

  async componentDidMount() {
    const id = this.context.shipment._id;
    // Check if window width is less than a certain threshold to determine mobile view
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768;
    this.setState({ isMobile });
  };

  render() {
    const {
      loading,
      expanded,
      door,
      inside,
      other,
      tracker,
      first,
      second,
      third,
      fourth,
      first1,
      second1,
      third1,
      fourth1,
      isMobile,
      showDropdown,
      showDropdown1,
      showDropdown2,
    } = this.state;
    const ShipmentsData = this.props.shipment;
    console.log(this.props, "proppppp");
    const { openShareTab } = this.props;

    if (loading) {
      return <div>loading</div>;
    }

    // Find the "door" inspection
    const doorInspection =
      ShipmentsData &&
      ShipmentsData.inspections &&
      ShipmentsData.inspections[0] &&
      ShipmentsData.inspections[0].fields;

    // Find the "door" inspection
    const loadingInspection =
      ShipmentsData &&
      ShipmentsData.inspections &&
      ShipmentsData.inspections[1] &&
      ShipmentsData.inspections[1].fields;

    // Find the "door" inspection
    const DestinationInspection =
      ShipmentsData &&
      ShipmentsData.inspections &&
      ShipmentsData.inspections[2] &&
      ShipmentsData.inspections[2].fields;

    // Extract images for the "door" inspection
    const doorImages =
      doorInspection && doorInspection[0] && doorInspection[0].images; // Assuming 'door' is the first inspection and it has images

    const insideImages =
      doorInspection && doorInspection[1] && doorInspection[1].images;

    const otherImages =
      doorInspection && doorInspection[3] && doorInspection[2].images;

    const trackerImages =
      doorInspection && doorInspection[3] && doorInspection[3].images;

    const firstImages =
      loadingInspection && loadingInspection[0] && loadingInspection[0].images; // Assuming 'door' is the first inspection and it has images

    const secondImages =
      loadingInspection && loadingInspection[1] && loadingInspection[1].images;

    const thirdImages =
      loadingInspection && loadingInspection[2] && loadingInspection[2].images;

    const fourthImages =
      loadingInspection && loadingInspection[3] && loadingInspection[3].images;

    const firstImages1 =
      DestinationInspection &&
      DestinationInspection[0] &&
      DestinationInspection[0].images; // Assuming 'door' is the first inspection and it has images

    const secondImages1 =
      DestinationInspection &&
      DestinationInspection[1] &&
      DestinationInspection[1].images;

    const thirdImages1 =
      DestinationInspection &&
      DestinationInspection[2] &&
      DestinationInspection[2].images;

    const fourthImages1 =
      DestinationInspection &&
      DestinationInspection[3] &&
      DestinationInspection[3].images;

    const preloading =
      ShipmentsData &&
      ShipmentsData.inspections[0] &&
      ShipmentsData.inspections[0];
    const origin =
      ShipmentsData &&
      ShipmentsData.inspections[1] &&
      ShipmentsData.inspections[1];
    const destination =
      ShipmentsData &&
      ShipmentsData.inspections[2] &&
      ShipmentsData.inspections[2];
    function renderInfoBox(location: any, timestamp: any, person: any) {
      return (
        <div className="shipment-details">
          <span style={{ display: "flex", alignItems: "center" }}>
            <span>
              <Icon size={19} name="location_on" />
            </span>
            <span style={{ marginLeft: "5px" }}>
              {location ? location : "N/As"}
            </span>
          </span>
          <span style={{ display: "flex", alignItems: "center" }}>
            <span>
              <Icon size={19} name="access_time" />
            </span>
            <span style={{ marginLeft: "5px" }}>
              {timestamp ? timestamp : "N/A"}
            </span>
          </span>
          <span style={{ display: "flex", alignItems: "center" }}>
            <span>
              <Icon size={19} name="person" />
            </span>
            <span style={{ marginLeft: "5px" }}>{person ? person : "N/A"}</span>
          </span>
        </div>
      );
    }

    return (
      <div
        className="scroll"
        style={
          {
            // height: !preloading && !origin && !destination ? "40%" : "",
            // overflowY:
            //   !preloading && !origin && !destination ? "unset" : "scroll",
            overflowY:"unset"
          }
        }
      >
        <div
          className="shipment-card__container expanded"
          style={{ margin: "0px", width: isMobile ? "330px" : "" }}
        >
          <div className="shlk-card shipment-card__box">
            <div className="shipment-card__top" style={{ padding: "15px" }}>
              <div
                className="shipment-card__info"
                style={{ display: "flex", width: "130%" }}
              >
                <div className="title">
                  {ShipmentsData && ShipmentsData.name}
                </div>
                <div
                  style={{
                    overflowY: isMobile ? "unset" :"scroll",
                    maxHeight:  isMobile ?"": "80vh",
                    scrollbarWidth: "thin",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ width: isMobile ? "85%" : "100%" }}>
                      <div style={{}}>
                        <div className="nav-heading">
                          <div
                            style={{
                              width: isMobile ? "93%" : "200px",
                              display: isMobile ? "flex" : "flex",
                              justifyContent: isMobile ? "flex-start" : "",
                              alignItems: isMobile ? "center" : "center",
                              cursor: "pointer",
                            }}
                            className="shipment-title"
                            onClick={() => this.doorClick()}
                          >
                            <span
                              style={{
                                width: isMobile ? "" : "",
                                fontSize: "15px",
                                fontFamily: "Roboto Condensed",
                                marginRight: "10px",
                              }}
                              onClick={this.toggleDropdown}
                            >
                              Preloading{" "}
                            </span>
                            {!this.props.isPublic && (
                              <>
                                <Icon
                                  size={23}
                                  name="camera_alt"
                                  onClick={openShareTab}
                                  className="cam-icon"
                                />
                                <Icon
                                  size={23}
                                  name="share"
                                  onClick={openShareTab}
                                  className="cam-icon"
                                />
                              </>
                            )}

                            {isMobile && (
                              <span
                                style={{
                                  width: "10%",
                                  marginLeft: "auto",
                                  marginRight: "0",
                                }}
                              >
                                <Icon
                                  size={28}
                                  name={
                                    showDropdown
                                      ? "keyboard_arrow_up_icon"
                                      : "keyboard_arrow_down_icon"
                                  }
                                  onClick={this.toggleDropdown}
                                />
                              </span>
                            )}
                          </div>
                          {!isMobile && preloading ? (
                            <>
                              {" "}
                              <div
                                style={{ position: "relative", left: "-1px" }}
                                className="arrow"
                              />{" "}
                              <div
                                style={{
                                  borderBottom: door
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: door ? "#19575c" : "#3d9aa1",
                                  marginLeft: "10px",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.doorClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = door
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Container
                              </div>
                              <div
                                style={{
                                  borderBottom: inside
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: inside ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.insideClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = inside
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Inside
                              </div>
                              <div
                                style={{
                                  borderBottom: other
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: other ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.otherClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = other
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Seal
                              </div>
                              <div
                                style={{
                                  borderBottom: tracker
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: tracker ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.trackerClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = tracker
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Tracker
                              </div>
                            </>
                          ) : (
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                position: "absolute",
                                marginLeft: "250px",
                                display: isMobile ? "none" : "",
                              }}
                            >
                              No Inspections Yet
                            </span>
                          )}
                        </div>
                        {isMobile && showDropdown && preloading && (
                          <div
                            className="dropdown"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                              background: "#e8eeef",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{
                                borderBottom: door
                                  ? "3px solid #19575c"
                                  : "3px solid transparent",
                                color: door ? "#19575c" : "#3d9aa1",
                                marginLeft: "10px",
                              }}
                              className="nav"
                              onClick={() => this.doorClick()}
                            >
                              Container
                            </div>
                            <div
                              style={{
                                borderBottom: inside
                                  ? "3px solid #19575c"
                                  : "3px solid transparent",
                                color: inside ? "#19575c" : "#3d9aa1",
                              }}
                              className="nav"
                              onClick={() => this.insideClick()}
                            >
                              Inside
                            </div>
                            <div
                              style={{
                                borderBottom: other
                                  ? "3px solid #19575c"
                                  : "3px solid transparent",
                                color: other ? "#19575c" : "#3d9aa1",
                              }}
                              className="nav"
                              onClick={() => this.otherClick()}
                            >
                              Seal
                            </div>
                            <div
                              style={{
                                borderBottom: tracker
                                  ? "3px solid #19575c"
                                  : "3px solid transparent",
                                color: tracker ? "#19575c" : "#3d9aa1",
                              }}
                              className="nav"
                              onClick={() => this.trackerClick()}
                            >
                              Tracker
                            </div>
                          </div>
                        )}
                        {!isMobile && (
                          <>
                            <div className="image-div-div">
                              <>
                                {" "}
                                {door &&
                                  doorImages &&
                                  doorImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          preloading.location &&
                                            preloading.location.city &&
                                            preloading.location.city,
                                          preloading.date && preloading.date,
                                          preloading.userName &&
                                            preloading.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {inside &&
                                  insideImages &&
                                  insideImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          preloading.location &&
                                            preloading.location.city &&
                                            preloading.location.city,
                                          preloading.date && preloading.date,
                                          preloading.userName &&
                                            preloading.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {other &&
                                  otherImages &&
                                  otherImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          preloading.location &&
                                            preloading.location.city &&
                                            preloading.location.city,
                                          preloading.date && preloading.date,
                                          preloading.userName &&
                                            preloading.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {tracker &&
                                  trackerImages &&
                                  trackerImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          preloading.location &&
                                            preloading.location.city &&
                                            preloading.location.city,
                                          preloading.date && preloading.date,
                                          preloading.userName &&
                                            preloading.userName
                                        )}
                                      </div>
                                    )
                                  )}
                              </>
                            </div>
                          </>
                        )}
                        {isMobile && showDropdown && (
                          <>
                            {" "}
                            {ShipmentsData &&
                            ShipmentsData.inspections &&
                            ShipmentsData.inspections[0] ? (
                              <div className="image-div-div">
                                <>
                                  {" "}
                                  {door &&
                                    doorImages &&
                                    doorImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            preloading.location &&
                                              preloading.location.city &&
                                              preloading.location.city,
                                            preloading.date && preloading.date,
                                            preloading.userName &&
                                              preloading.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {inside &&
                                    insideImages &&
                                    insideImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            preloading.location &&
                                              preloading.location.city &&
                                              preloading.location.city,
                                            preloading.date && preloading.date,
                                            preloading.userName &&
                                              preloading.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {other &&
                                    otherImages &&
                                    otherImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            preloading.location &&
                                              preloading.location.city &&
                                              preloading.location.city,
                                            preloading.date && preloading.date,
                                            preloading.userName &&
                                              preloading.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {tracker &&
                                    trackerImages &&
                                    trackerImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            preloading.location &&
                                              preloading.location.city &&
                                              preloading.location.city,
                                            preloading.date && preloading.date,
                                            preloading.userName &&
                                              preloading.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                </>
                              </div>
                            ) : (
                              <span
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                No Inspections Yet
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ width: isMobile ? "85%" : "100%" }}>
                      <div style={{}}>
                        <div className="nav-heading">
                          <div
                            style={{
                              width: isMobile ? "93%" : "200px",
                              display: isMobile ? "flex" : "flex",
                              justifyContent: isMobile ? "flex-start" : "",
                              alignItems: isMobile ? "center" : "center",
                              cursor: "pointer",
                            }}
                            className="shipment-title"
                            onClick={() => this.firstClick()}
                          >
                            <span
                              style={{
                                width: isMobile ? "" : "",
                                fontSize: "15px",
                                fontFamily: "Roboto Condensed",
                                marginRight: "10px",
                              }}
                              onClick={this.toggleDropdown1}
                            >
                              Origin
                            </span>
                            {!this.props.isPublic && (
                              <>
                                <Icon
                                  size={23}
                                  name="camera_alt"
                                  onClick={openShareTab}
                                  className="cam-icon"
                                />
                                <Icon
                                  size={23}
                                  name="share"
                                  onClick={openShareTab}
                                  className="cam-icon"
                                />
                              </>
                            )}
                            {isMobile && (
                              <span
                                style={{
                                  width: "10%",
                                  marginLeft: "auto",
                                  marginRight: "0",
                                }}
                              >
                                <Icon
                                  size={28}
                                  name={
                                    showDropdown1
                                      ? "keyboard_arrow_up_icon"
                                      : "keyboard_arrow_down_icon"
                                  }
                                  onClick={this.toggleDropdown1}
                                />
                              </span>
                            )}
                          </div>

                          {!isMobile && origin ? (
                            <>
                              <div
                                style={{
                                  position: "relative",
                                  left: "-1px",
                                }}
                                className="arrow"
                              />
                              <div
                                style={{
                                  borderBottom: first
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: first ? "#19575c" : "#3d9aa1",
                                  marginLeft: "10px",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.firstClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = first
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Container
                              </div>
                              <div
                                style={{
                                  borderBottom: second
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: second ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.secondClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = second
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Cargo
                              </div>
                              <div
                                style={{
                                  borderBottom: third
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: third ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.thirdClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = third
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Seal
                              </div>
                              <div
                                style={{
                                  borderBottom: fourth
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: fourth ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.fourthClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = fourth
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Tracker
                              </div>
                            </>
                          ) : (
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                position: "absolute",
                                marginLeft: "250px",
                                display: isMobile ? "none" : "",
                              }}
                            >
                              No Inspections Yet
                            </span>
                          )}
                        </div>
                        {isMobile && showDropdown1 && origin && (
                          <div
                            className="dropdown"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                              background: "#e8eeef",
                              padding: "5px",
                            }}
                          >
                            <>
                              <div
                                style={{
                                  borderBottom: first
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: first ? "#19575c" : "#3d9aa1",
                                  marginLeft: "10px",
                                }}
                                className="nav"
                                onClick={() => this.firstClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = first
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Container
                              </div>
                              <div
                                style={{
                                  borderBottom: second
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: second ? "#19575c" : "#3d9aa1",
                                }}
                                className="nav"
                                onClick={() => this.secondClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = second
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Cargo
                              </div>
                              <div
                                style={{
                                  borderBottom: third
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: third ? "#19575c" : "#3d9aa1",
                                }}
                                className="nav"
                                onClick={() => this.thirdClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = third
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Seal
                              </div>
                              <div
                                style={{
                                  borderBottom: fourth
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: fourth ? "#19575c" : "#3d9aa1",
                                }}
                                className="nav"
                                onClick={() => this.fourthClick()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = fourth
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Tracker
                              </div>
                            </>
                          </div>
                        )}
                        {!isMobile && (
                          <>
                            <>
                              {" "}
                              <div className="image-div-div">
                                {first &&
                                  firstImages &&
                                  firstImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          origin.location &&
                                            origin.location.city &&
                                            origin.location.city,
                                          origin.date && origin.date,
                                          origin.userName && origin.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {second &&
                                  secondImages &&
                                  secondImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          origin.location &&
                                            origin.location.city &&
                                            origin.location.city,
                                          origin.date && origin.date,
                                          origin.userName && origin.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {third &&
                                  thirdImages &&
                                  thirdImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          origin.location &&
                                            origin.location.city &&
                                            origin.location.city,
                                          origin.date && origin.date,
                                          origin.userName && origin.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {fourth &&
                                  fourthImages &&
                                  fourthImages.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          origin.location &&
                                            origin.location.city &&
                                            origin.location.city,
                                          origin.date && origin.date,
                                          origin.userName && origin.userName
                                        )}
                                      </div>
                                    )
                                  )}
                              </div>
                            </>
                          </>
                        )}
                        {isMobile && showDropdown1 && (
                          <>
                            {ShipmentsData &&
                            ShipmentsData.inspections &&
                            ShipmentsData.inspections[1] ? (
                              <>
                                {" "}
                                <div className="image-div-div">
                                  {first &&
                                    firstImages &&
                                    firstImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            origin.location &&
                                              origin.location.city &&
                                              origin.location.city,
                                            origin.date && origin.date,
                                            origin.userName && origin.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {second &&
                                    secondImages &&
                                    secondImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            origin.location &&
                                              origin.location.city &&
                                              origin.location.city,
                                            origin.date && origin.date,
                                            origin.userName && origin.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {third &&
                                    thirdImages &&
                                    thirdImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            origin.location &&
                                              origin.location.city &&
                                              origin.location.city,
                                            origin.date && origin.date,
                                            origin.userName && origin.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {fourth &&
                                    fourthImages &&
                                    fourthImages.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            origin.location &&
                                              origin.location.city &&
                                              origin.location.city,
                                            origin.date && origin.date,
                                            origin.userName && origin.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                </div>
                              </>
                            ) : (
                              <span
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                No Inspections Yet
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ width: isMobile ? "85%" : "100%" }}>
                      <div style={{}}>
                        <div className="nav-heading">
                          <div
                            style={{
                              width: isMobile ? "93%" : "200px",
                              display: isMobile ? "flex" : "flex",
                              justifyContent: isMobile ? "flex-start" : "",
                              alignItems: isMobile ? "center" : "center",
                              cursor: "pointer",
                            }}
                            className="shipment-title"
                            onClick={() => this.firstClick1()}
                          >
                            <span
                              style={{
                                width: isMobile ? "" : "",
                                fontSize: "15px",
                                fontFamily: "Roboto Condensed",
                                marginRight: "10px",
                              }}
                              onClick={this.toggleDropdown2}
                            >
                              Destination
                            </span>
                            {!this.props.isPublic && (
                              <>
                                <Icon
                                  size={23}
                                  name="camera_alt"
                                  onClick={openShareTab}
                                  className="cam-icon"
                                />
                                <Icon
                                  size={23}
                                  name="share"
                                  onClick={openShareTab}
                                  className="cam-icon"
                                />
                              </>
                            )}
                            {isMobile && (
                              <span
                                style={{
                                  width: "10%",
                                  marginLeft: "auto",
                                  marginRight: "0",
                                }}
                              >
                                <Icon
                                  size={28}
                                  name={
                                    showDropdown2
                                      ? "keyboard_arrow_up_icon"
                                      : "keyboard_arrow_down_icon"
                                  }
                                  onClick={this.toggleDropdown2}
                                />
                              </span>
                            )}
                          </div>

                          {!isMobile && destination ? (
                            <>
                              <div
                                style={{
                                  position: "relative",
                                  left: "-1px",
                                }}
                                className="arrow"
                              />
                              <div
                                style={{
                                  borderBottom: first1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: first1 ? "#19575c" : "#3d9aa1",
                                  marginLeft: "10px",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.firstClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = first1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Container
                              </div>
                              <div
                                style={{
                                  borderBottom: second1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: second1 ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.secondClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = second1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Cargo
                              </div>
                              <div
                                style={{
                                  borderBottom: third1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: third1 ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.thirdClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = third1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Seal
                              </div>
                              <div
                                style={{
                                  borderBottom: fourth1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: fourth1 ? "#19575c" : "#3d9aa1",
                                  width: isMobile ? "" : "100px",
                                }}
                                className="nav"
                                onClick={() => this.fourthClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = fourth1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Tracker
                              </div>
                            </>
                          ) : (
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                position: "absolute",
                                marginLeft: "250px",
                                display: isMobile ? "none" : "",
                              }}
                            >
                              No Inspections Yet
                            </span>
                          )}
                        </div>
                        {isMobile && showDropdown2 && destination && (
                          <div
                            className="dropdown"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                              background: "#e8eeef",
                              padding: "5px",
                            }}
                          >
                            <>
                              <div
                                style={{
                                  borderBottom: first1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: first1 ? "#19575c" : "#3d9aa1",
                                  marginLeft: "10px",
                                }}
                                className="nav"
                                onClick={() => this.firstClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = first1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Container
                              </div>
                              <div
                                style={{
                                  borderBottom: second1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: second1 ? "#19575c" : "#3d9aa1",
                                }}
                                className="nav"
                                onClick={() => this.secondClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = second1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Cargo
                              </div>
                              <div
                                style={{
                                  borderBottom: third1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: third1 ? "#19575c" : "#3d9aa1",
                                }}
                                className="nav"
                                onClick={() => this.thirdClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = third1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Seal
                              </div>
                              <div
                                style={{
                                  borderBottom: fourth1
                                    ? "3px solid #19575c"
                                    : "3px solid transparent",
                                  color: fourth1 ? "#19575c" : "#3d9aa1",
                                }}
                                className="nav"
                                onClick={() => this.fourthClick1()}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.color = "#19575c";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.color = fourth1
                                    ? "#19575c"
                                    : "#3d9aa1";
                                }}
                              >
                                Tracker
                              </div>
                            </>
                          </div>
                        )}
                        {!isMobile && (
                          <>
                            <>
                              {" "}
                              <div className="image-div-div">
                                {first1 &&
                                  firstImages1 &&
                                  firstImages1.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          destination.location &&
                                            destination.location.city &&
                                            destination.location.city,
                                          destination.date && destination.date,
                                          destination.userName &&
                                            destination.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {second1 &&
                                  secondImages1 &&
                                  secondImages1.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          destination.location &&
                                            destination.location.city &&
                                            destination.location.city,
                                          destination.date && destination.date,
                                          destination.userName &&
                                            destination.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {third1 &&
                                  thirdImages1 &&
                                  thirdImages1.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          destination.location &&
                                            destination.location.city &&
                                            destination.location.city,
                                          destination.date && destination.date,
                                          destination.userName &&
                                            destination.userName
                                        )}
                                      </div>
                                    )
                                  )}
                                {fourth1 &&
                                  fourthImages1 &&
                                  fourthImages1.map(
                                    (image: string, index: number) => (
                                      <div key={index} className="image-div">
                                        <img
                                          src={image}
                                          alt={`door ${index}`}
                                          className="image"
                                        />
                                        {renderInfoBox(
                                          destination.location &&
                                            destination.location.city &&
                                            destination.location.city,
                                          destination.date && destination.date,
                                          destination.userName &&
                                            destination.userName
                                        )}
                                      </div>
                                    )
                                  )}
                              </div>
                            </>
                          </>
                        )}
                        {isMobile && showDropdown2 && (
                          <>
                            {ShipmentsData &&
                            ShipmentsData.inspections &&
                            ShipmentsData.inspections[2] ? (
                              <>
                                {" "}
                                <div className="image-div-div">
                                  {first1 &&
                                    firstImages1 &&
                                    firstImages1.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            destination.location &&
                                              destination.location.city &&
                                              destination.location.city,
                                            destination.date &&
                                              destination.date,
                                            destination.userName &&
                                              destination.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {second1 &&
                                    secondImages1 &&
                                    secondImages1.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            destination.location &&
                                              destination.location.city &&
                                              destination.location.city,
                                            destination.date &&
                                              destination.date,
                                            destination.userName &&
                                              destination.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {third1 &&
                                    thirdImages1 &&
                                    thirdImages1.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            destination.location &&
                                              destination.location.city &&
                                              destination.location.city,
                                            destination.date &&
                                              destination.date,
                                            destination.userName &&
                                              destination.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                  {fourth1 &&
                                    fourthImages1 &&
                                    fourthImages1.map(
                                      (image: string, index: number) => (
                                        <div key={index} className="image-div">
                                          <img
                                            src={image}
                                            alt={`door ${index}`}
                                            className="image"
                                          />
                                          {renderInfoBox(
                                            destination.location &&
                                              destination.location.city &&
                                              destination.location.city,
                                            destination.date &&
                                              destination.date,
                                            destination.userName &&
                                              destination.userName
                                          )}
                                        </div>
                                      )
                                    )}
                                </div>
                              </>
                            ) : (
                              <span
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                No Inspections Yet
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ScanBox);
