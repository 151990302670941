// import React, { Component, createRef } from "react";
// import Input from "components/Inputs/Input/Default";
// import TagsList, { Tag } from "components/Tags/List";
// import Datalist from "components/Inputs/Datalist";
// import Selectable from "components/Inputs/Selectable";
// import { reducer, toggle } from "utils/functions";
// import { addTagHandler } from "utils/tags";
// import { getTagsList } from "services/tags";
// import "./styles.scss";
// import RoutesMap from "components/GoogleMap/RoutesMap";
// import { ShipmentContext } from "components/Shipment/context";
// import MapComponent from "components/GoogleMap/RouteMapv2";
// import AbstractMap from "components/GoogleMap/Abstract";
// import GoogleMapReact from "google-map-react";
// import Icon from "components/Icon";

// interface Props {
//   filter?: {
//     status: string[];
//     tags: any[];
//     createdAt?: string;
//     updatedAt?: string;
//   };
//   filterCallback: Function;
//   allShipmentPage?: boolean;
// }

// interface State {
//   tags: any[];
//   filter: {
//     status: string[];
//     tags: any[];
//     searchText?: string;
//     createdAt?: string;
//     updatedAt?: string;
//   };
//   showFilterForm: boolean;
// }

// export default class ShipmentsFilter extends Component<Props, State> {
//   private form: any = createRef();

//   static defaultProps = {
//     filter: { status: ["ongoing", "completed"], tags: [] },
//   };

//   constructor(props: Props) {
//     super(props);

//     this.state = {
//       tags: [],
//       filter: this.props.filter!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
//       showFilterForm: false,
//     };
//   }
//   toggleFilterForm = () => {
//     this.setState((prevState: any) => ({
//       showFilterForm: !prevState.showFilterForm,
//     }));
//   };

//   componentDidMount() {
//     getTagsList().then((tags: any) => this.setState({ tags }));
//   }

//   onFilterChange = (event: React.ChangeEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     event.stopPropagation();

//     const filter: any = this.state.filter;
//     const formData: any = new FormData(this.form.current);

//     Array.from(formData.entries).forEach(([key, value]: any) => {
//       filter[key] = value;
//     });

//     this.setState({ filter }, () =>
//       this.props.filterCallback(this.state.filter)
//     );
//   };

//   addTag = (tags: any[]) =>
//     this.setState({ filter: Object.assign(this.state.filter, { tags }) });

//   removeTag = (event: any, tag: any, tagsInUse: any[]) => {
//     const tags = tagsInUse.filter(
//       ({ _id }: { _id: string }) => _id !== tag._id
//     );

//     this.setState({ filter: Object.assign(this.state.filter, { tags }) });
//     this.onFilterChange(event);
//   };
//   render() {
//     const { allShipmentPage } = this.props;
//     const [tagsInUse, tagsToUse] = reducer(this.state.tags, (tag: Tag) =>
//       this.state.filter.tags.some(({ _id }: Tag) => _id === tag._id)
//     );
//     const { showFilterForm } = this.state;
//     return (
//       <>
//         <div style={{ display: "inline" }}>
//           {allShipmentPage && (
//             <>
//               {" "}
//               <div
//                 style={{
//                   display: "flex",
//                   // justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <h3 className="shlk-title">Shipments </h3>
//                 <div
//                   className="shlk-button shlk-button__solid"
//                   onClick={this.toggleFilterForm}
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     background: "none",
//                     border: "none",
//                     color: "#19575c",
//                   }}
//                 >
//                   <Icon size={25} name="tune" />
//                 </div>
//               </div>
//             </>
//           )}

//           {showFilterForm && (
//             <form
//               ref={this.form}
//               onChange={this.onFilterChange}
//               className="shlk-filter__container"
//               // style={{ marginLeft: "10px" }}
//             >
//               <div className="filter-inputs">
//                 <div className="filter-inputs__ongoing">
//                   <Selectable
//                     name="ongoing"
//                     checked={this.state.filter.status.includes("ongoing")}
//                     onClick={() => {
//                       this.setState({
//                         filter: Object.assign(this.state.filter, {
//                           status: toggle(this.state.filter.status, "ongoing"),
//                         }),
//                       });
//                     }}
//                   >
//                     Ongoing
//                   </Selectable>
//                 </div>
//                 <div className="filter-inputs__completed">
//                   <Selectable
//                     name="completed"
//                     checked={this.state.filter.status.includes("completed")}
//                     onClick={() =>
//                       this.setState({
//                         filter: Object.assign(this.state.filter, {
//                           status: toggle(this.state.filter.status, "completed"),
//                         }),
//                       })
//                     }
//                   >
//                     Completed
//                   </Selectable>
//                 </div>
//               </div>
//               {tagsInUse.length > 0 && (
//                 <TagsList
//                   tags={tagsInUse}
//                   onRemove={(tag: any, event: any) =>
//                     this.removeTag(event, tag, tagsInUse)
//                   }
//                 />
//               )}
//               <div style={{ display: "flex", marginBottom: "5px" }}>
//                 <div className="filter-inputs__date">
//                   <Input
//                     label="From:"
//                     type="date"
//                     name="createdAt"
//                     placeholder="YYYY-MM-DD"
//                     pattern="\d{4}-\d{2}-\d{2}"
//                   />
//                 </div>
//                 <div className="filter-inputs__date">
//                   <Input
//                     label="Until:"
//                     type="date"
//                     name="updatedAt"
//                     placeholder="YYYY-MM-DD"
//                     pattern="\d{4}-\d{2}-\d{2}"
//                   />
//                 </div>
//               </div>
//               <div style={{ display: "flex" }}>
//                 <div
//                   className="filter-inputs__search"
//                   style={{ marginRight: "5px" }}
//                 >
//                   <Input name="searchText" type="text" placeholder="Search" />
//                 </div>
//                 <Datalist
//                   disabled={!tagsToUse.length}
//                   datalist={tagsToUse}
//                   className="filter-inputs__tags"
//                   name="tags-list"
//                   placeholder={
//                     tagsToUse.length ? "Select tag" : "No available options"
//                   }
//                   onSelect={(event: any) =>
//                     addTagHandler(
//                       event,
//                       tagsToUse,
//                       this.state.filter.tags,
//                       this.addTag
//                     )
//                   }
//                 />
//               </div>
//             </form>
//           )}
//         </div>
//       </>
//     );
//   }
// }

import React, { Component, createRef } from "react";
import Input from "components/Inputs/Input/Default";
import TagsList, { Tag } from "components/Tags/List";
import Datalist from "components/Inputs/Datalist";
import Selectable from "components/Inputs/Selectable";
import { reducer, toggle } from "utils/functions";
import { addTagHandler } from "utils/tags";
import { getTagsList } from "services/tags";
import "./styles.scss";
import Icon from "components/Icon";

interface Props {
  filter?: {
    status: string[];
    tags: any[];
    createdAt?: string;
    updatedAt?: string;
  };
  filterCallback: (filter: any) => void;
  allShipmentPage?: boolean;
}

interface State {
  tags: any[];
  filter: {
    status: string[];
    tags: any[];
    searchText?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  showFilterForm: boolean;
}

export default class ShipmentsFilter extends Component<Props, State> {
  static defaultProps = {
    filter: { status: ["ongoing", "completed"], tags: [] },
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      tags: [],
      filter: { ...this.props.filter! }, // spread operator to avoid mutation
      showFilterForm: false,
    };
  }

  componentDidMount() {
    getTagsList().then((tags: any) => this.setState({ tags }));
  }

  // Handle changes for each input and update state accordingly
  handleInputChange = (name: string, value: any) => {
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          [name]: value,
        },
      }),
      () => this.props.filterCallback(this.state.filter) // callback after state update
    );
  };

  toggleFilterForm = () => {
    this.setState((prevState) => ({
      showFilterForm: !prevState.showFilterForm,
    }));
  };

  addTag = (tags: any[]) => {
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          tags,
        },
      }),
      () => this.props.filterCallback(this.state.filter)
    );
  };

  removeTag = (event: any, tag: any, tagsInUse: any[]) => {
    const tags = tagsInUse.filter(({ _id }: { _id: string }) => _id !== tag._id);
    this.setState(
      (prevState) => ({
        filter: {
          ...prevState.filter,
          tags,
        },
      }),
      () => this.props.filterCallback(this.state.filter)
    );
  };

  render() {
    const { allShipmentPage } = this.props;
    const [tagsInUse, tagsToUse] = reducer(this.state.tags, (tag: Tag) =>
      this.state.filter.tags.some(({ _id }: Tag) => _id === tag._id)
    );
    const { showFilterForm } = this.state;

    return (
      <>
        <div style={{ display: "inline" }}>
          {allShipmentPage && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <h3 className="shlk-title">Shipments</h3>
              <div
                className="shlk-button shlk-button__solid"
                onClick={this.toggleFilterForm}
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "none",
                  border: "none",
                  color: "#19575c",
                }}
              >
                <Icon size={25} name="tune" />
              </div>
            </div>
          )}

          {showFilterForm && (
            <form className="shlk-filter__container">
              <div className="filter-inputs">
                <div className="filter-inputs__ongoing">
                  <Selectable
                    name="ongoing"
                    checked={this.state.filter.status.includes("ongoing")}
                    onClick={() =>
                      this.handleInputChange(
                        "status",
                        toggle(this.state.filter.status, "ongoing")
                      )
                    }
                  >
                    Ongoing
                  </Selectable>
                </div>
                <div className="filter-inputs__completed">
                  <Selectable
                    name="completed"
                    checked={this.state.filter.status.includes("completed")}
                    onClick={() =>
                      this.handleInputChange(
                        "status",
                        toggle(this.state.filter.status, "completed")
                      )
                    }
                  >
                    Completed
                  </Selectable>
                </div>
              </div>

              {tagsInUse.length > 0 && (
                <TagsList
                  tags={tagsInUse}
                  onRemove={(tag: any, event: any) =>
                    this.removeTag(event, tag, tagsInUse)
                  }
                />
              )}

              <div style={{ display: "flex", marginBottom: "5px" }}>
                <Input
                  label="From:"
                  type="date"
                  name="createdAt"
                  value={this.state.filter.createdAt || ""}
                  onChange={(e:any) =>
                    this.handleInputChange("createdAt", e.target.value)
                  }
                />
                <Input
                  label="Until:"
                  type="date"
                  name="updatedAt"
                  value={this.state.filter.updatedAt || ""}
                  onChange={(e:any) =>
                    this.handleInputChange("updatedAt", e.target.value)
                  }
                />
              </div>

              <div style={{ display: "flex" }}>
                <Input
                  name="searchText"
                  type="text"
                  value={this.state.filter.searchText || ""}
                  placeholder="Search"
                  onChange={(e:any) =>
                    this.handleInputChange("searchText", e.target.value)
                  }
                />
                <Datalist
                  disabled={!tagsToUse.length}
                  datalist={tagsToUse}
                  className="filter-inputs__tags"
                  name="tags-list"
                  placeholder={tagsToUse.length ? "Select tag" : "No available options"}
                  onSelect={(event: any) =>
                    addTagHandler(
                      event,
                      tagsToUse,
                      this.state.filter.tags,
                      this.addTag
                    )
                  }
                />
              </div>
            </form>
          )}
        </div>
      </>
    );
  }
}
