import React, { Component } from 'react';
import Range from 'components/Inputs/Range';
import Selectable from 'components/Inputs/Selectable';
import SelectableList from 'components/SelectableList';
import { getTagsList } from 'services/tags';
import './styles.scss';

interface Props {
  onChange: any;
  filter: any;
  countries: any[];
}

interface State {
  tags: Array<any>;
}

export default class FilterTrackers extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tags: []
    };
  }

  componentDidMount() {
    getTagsList().then((tags: any) => {
      this.setState({ tags });
    });
  }


  onFilterChange = (field: string, value: any) => {
    const filter = Object.assign(this.props.filter, { [field]: value });

    this.props.onChange(filter);
  }

  render() {
    const { filter, countries } = this.props;

    return (
      <div className="filter-box">
        <div className="box-field">
          <Range min="0" max="100" value={filter.batteryMin} label="Min battery" units="%"
                  update={(value: any) => this.onFilterChange("batteryMin", value)}/>

          <Range min="0" max="100" value={filter.batteryMax} label="Max battery" units="%"
                  update={(value: any) => this.onFilterChange("batteryMax", value)}/>
        </div>
        <div className="box-field wrap">
          <Selectable name="Unallocated" checked={filter.inactive}
                    onClick={(value: any) => this.onFilterChange("inactive", value)} />

          <Selectable name="On shipment" checked={filter.active}
                    onClick={(value: any) => this.onFilterChange("active", value)} />

          <Selectable name="Onboard" checked={filter.onboard}
                    onClick={(value: any) => this.onFilterChange("onboard", value)} />

        </div>
        <div className="box-field">
          <span className="field-label no-fill">Countries</span>
          <SelectableList name="countries" list={countries} selected={this.props.filter.countries} onChange={(countries: any[]) => this.onFilterChange('countries', countries)}/>
        </div>
        <div className="box-field">
          <span className="field-label no-fill">Tags</span>
          <SelectableList name="tags" list={this.state.tags.map(({ name }) => name)} selected={this.props.filter.tags} onChange={(tags: any[]) => this.onFilterChange('tags', tags)}/>
        </div>
      </div>
    );
  }
}
