import React, { Component, ReactElement } from "react";
import { trackEvent } from "utils/analytics";
import Icon from "components/Icon";
import "./styles.scss";

interface Props {
  type: string;
  name: string;
  label?: string;
  list?: string;
  value?: string;
  defaultValue?: string;
  icon?: ReactElement | string;
  placeholder?: string;
  pattern?: string;
  onInput?: any;
  onKeyUp?: any;
  onChange?: any;
  onBlur?: any;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
}

export default class Input extends Component<Props> {
  static defaultProps = { className: "" };

  shouldTrack = () => this.props.type !== "password";

  trackEventWithCallback(
    event: any,
    method: string,
    callback: Function | null
  ) {
    const value = event.currentTarget.value;

    this.shouldTrack() && trackEvent(method, this.props.name, value);
    callback && callback(event);
  }

  onKeyUp = (event: any) => {
    const code = event.keyCode || event.which;

    if (code === 13) {
      // Enter
      event.target.blur();
    }

    this.trackEventWithCallback(
      event,
      "onKeyUpDefaultForm",
      this.props.onKeyUp
    );
  };

  renderInput() {
    return (
      <input
        className={`form-input ${this.props.className}`}
        name={this.props.name}
        type={this.props.type}
        list={this.props.list}
        value={this.props.value}
        defaultValue={this.props.defaultValue}
        pattern={this.props.pattern}
        checked={this.props.checked}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        onKeyUp={this.onKeyUp}
        onBlur={(event) =>
          this.trackEventWithCallback(
            event,
            "onBlurDefaultForm",
            this.props.onBlur
          )
        }
        onInput={(event) =>
          this.trackEventWithCallback(
            event,
            "onInputDefaultForm",
            this.props.onInput
          )
        }
        onChange={(event) =>
          this.trackEventWithCallback(
            event,
            "onChangeDefaultForm",
            this.props.onChange
          )
        }
      />
    );
  }
  render() {
    const { icon, label } = this.props;

    console.log(this.props.name, "from");

    if (label || icon) {
      return (
        <label
          className={`form-label ${
            this.props.name === "createdAt" || this.props.name === "updatedAt"
              ? "From-style"
              : ""
          }`}
        >
          <span className="form-label__name">{label}</span>
          {icon ? (
            typeof icon === "string" ? (
              <Icon name={icon} size={18} className="form-string-icon" />
            ) : (
              <div className="form-icon">{icon}</div>
            )
          ) : null}
          {this.renderInput()}
        </label>
      );
    }

    return this.renderInput();
  }
}
