import { shlkAPI, clean } from 'services/api';

export const getUser = async () => {
  return shlkAPI('user/get');
};

export const updateUser = async (updateInfo: object) => {
  return shlkAPI('user/update', updateInfo).then(clean);
};

export const getAllUsers = async () => {
  return shlkAPI('user/all');
};

export const getRoles = async () => {
  return shlkAPI('user/roles');
};

export const manageUser = async (email: string) => {
  return shlkAPI('user/manage', { email }).then(clean);
};

