import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

import Icon from "components/Icon";
import Tags from "components/Tags/List";
import { getSharingCompany } from "utils/shipment";
import { calculatePercentageDone } from "utils/location";
import "./styles.scss";
import ship_icon from "../../../assets/icon/ship_icon.png";
import { eventNames } from "process";
import BoxButton from "components/Box/Button";
import {
  completeShipment,
  deleteShipment,
  getEmissionhubs,
  getEmissionsStats,
  getShipmentInfo,
} from "services/shipments";
import shipmentdata from "./mock_shipment.json";
import Button from "components/Inputs/Button";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface State {
  hoveredLineIndex: number | null;
  loading: boolean;
  expanded: boolean;
  isMobile: boolean;
  emissionStats: any;
  ShipmentsData: any;
  hubsEmission: any;
  currentSlide: any;
  isVisible: any;
}

interface Props extends RouteComponentProps {
  shipment: any;
  lines: number[];
  showBoxButton: boolean;
  actions: boolean;
  onHover?: (origin: any, destination: any) => void;
  onClick?: (origin: any, destination: any) => void;
  allShipmentPage?: boolean;
  id?: any;
  isPublic?: any;
  openTagTab: () => void; // New prop for opening the Share tab
}

interface AlarmEvents {
  [key: string]: {
    ack: boolean;
    events: any[];
  };
}

class ShipmentCardV1 extends Component<Props, State> {
  private alarmEvents: AlarmEvents = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredLineIndex: null,
      loading: true,
      expanded: false,
      isMobile: false,
      emissionStats: {},
      ShipmentsData: {},
      hubsEmission: {},
      currentSlide: 0,
      isVisible: true,
    };
    // Bind event handlers
    this.handleLineHover = this.handleLineHover.bind(this);
    this.handleLineLeave = this.handleLineLeave.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleLineHover(index: number) {
    this.setState({ hoveredLineIndex: index });
  }

  handleLineLeave() {
    this.setState({ hoveredLineIndex: null });
  }
  handleLineClick(index: number): void {
    this.setState({ hoveredLineIndex: null });
  }
  handleClose() {
    this.setState({ isVisible: false });
  }
  toggleExpanded(event: any) {
    event.preventDefault();
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  nextSlide = (event: any) => {
    event.preventDefault();
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide + 1) % 3,
    }));
  };

  prevSlide = (event: any) => {
    event.preventDefault();
    this.setState((prevState) => ({
      currentSlide: (prevState.currentSlide - 1 + 3) % 3,
    }));
  };

  async componentDidMount() {
    // Check if window width is less than a certain threshold to determine mobile view
    const id = this.props.shipment._id;
    window.addEventListener("resize", this.checkMobile);
    this.checkMobile();

    try {
      const response = await getEmissionsStats({
        id,
      });
      // Assuming `nearestNames` comes from the response.
      const emissionStatsData = response; // Adjust this line based on actual response structure
      this.setState({ emissionStats: emissionStatsData });
    } catch (error) {
      console.error("Error fetching port list:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    const isMobile = window.innerWidth < 768; // Adjust threshold as needed
    this.setState({ isMobile });
  };

  showAlarmIcons() {
    const alarms: any = [];
    const ShipmentsData = this.props.shipment;

    if (
      ShipmentsData &&
      ShipmentsData.transports &&
      ShipmentsData.transports[0] &&
      ShipmentsData.transports[0].events
    ) {
      // Check if there's an unacknowledged delay event
      const delayEvent =
        ShipmentsData &&
        ShipmentsData.transports[0].events.find(
          (event: any) => event.ack === false && event.type === "DELAY"
        );
      const idleEvent =
        ShipmentsData &&
        ShipmentsData.transports[0].events.find(
          (event: any) => event.ack === false && event.type === "IDLE"
        );
      const temperatureEvent =
        ShipmentsData &&
        ShipmentsData.transports[0].events.find(
          (event: any) => event.ack === false && event.type === "TEMPERATURE"
        );
      const batteryEvent =
        ShipmentsData &&
        ShipmentsData.transports[0].events.find(
          (event: any) => event.ack === false && event.type === "BATTERY"
        );
      const lightEvent =
        ShipmentsData &&
        ShipmentsData.transports[0].events.find(
          (event: any) => event.ack === false && event.type === "LIGHT"
        );

      // Render delay icon if there's an unacknowledged delay event
      if (delayEvent) {
        alarms.push(
          <div style={{}}>
            <div style={{ width: "20px", height: "20px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/delay.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (temperatureEvent) {
        alarms.push(
          <div
            style={{
              background: "rgb(193, 28, 28)",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden", // Ensure the icon doesn't overflow the container
              marginTop: "5px",
            }}
          >
            <div style={{ width: "15px", height: "15px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/temperature.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (batteryEvent) {
        alarms.push(
          <div
            style={{
              background: "rgb(193, 28, 28)",
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden", // Ensure the icon doesn't overflow the container
              marginTop: "5px",
            }}
          >
            <div style={{ width: "15px", height: "15px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/battery.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (lightEvent) {
        alarms.push(
          <div style={{}}>
            <div style={{ width: "20px", height: "20px" }}>
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/LIGHT.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                }}
              />
            </div>
          </div>
        );
      }
      if (!delayEvent && !temperatureEvent && !batteryEvent && !lightEvent) {
        alarms.push(
          <div style={{}}>
            <div
              style={{
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <ReactSVG
                key="delay-icon"
                className="event-icon__delay"
                src={"/assets/svg/GREEN.svg"}
                style={{
                  height: "100%",
                  width: "100%",
                  fill: "white",
                  marginRight: "20px",
                }}
              />
              <div style={{ fontSize: "15px", color: "green" }}>
                No Alerts Detected
              </div>
            </div>
          </div>
        );
      }
    }

    return alarms;
  }

  // updateShipment = (serviceFunction: any, status: string) => {
  //   // this.setState({ loading: true });

  //   serviceFunction(this.props.shipment._id).then(() => {
  //     const shipment = this.props.shipment;
  //     shipment.status = status;
  //     this.context.updateShipment(shipment);
  //     // this.setState({ loading: false });
  //   });
  // };
  updateShipment = (serviceFunction: any, status: string): Promise<void> => {
    return serviceFunction(this.props.shipment._id).then(() => {
      const shipment = this.props.shipment;
      shipment.status = status;
      this.context.updateShipment(shipment);
    });
  };

  complete = () => {
    if (
      window.confirm(
        "Are you sure you want to complete the shipment? \nThis action is not reversible"
      )
    ) {
      this.updateShipment(completeShipment, "completed");
    }
  };

  // delete = () => {
  //   if (
  //     window.confirm(
  //       "Are you sure you want to cancel the shipment? \nThis action is not reversible"
  //     )
  //   ) {
  //     this.updateShipment(deleteShipment, "canceled");
  //   }
  // };
  delete = (event: any) => {
    // Prevent default behavior if this is an event handler (e.g., form submission or button click)
    if (event) {
      event.preventDefault();
    }

    if (
      window.confirm(
        "Are you sure you want to cancel the shipment? \nThis action is not reversible"
      )
    ) {
      this.updateShipment(deleteShipment, "canceled")
        .then(() => {
          // Redirect to home page after successful deletion
          console.log("Delete Shipments");
          this.props.history.push("/");
        })
        .catch((error) => {
          console.error("Error canceling shipment:", error);
          // Redirect to home page even if an error occurs
          this.props.history.push("/");
        });
    }
  };

  render() {
    console.log(this.props.openTagTab, "publoc");
    const { isMobile } = this.state;
    const { allShipmentPage } = this.props;
    const { emissionStats } = this.state;
    const { loading } = this.state;
    const { currentSlide } = this.state;
    // const { hubsEmission } = this.state;
    console.log(this.props.shipment, "props shipment");
    const ShipmentsData = this.props.shipment;

    if (loading) {
      return <div>Loading...</div>;
    }

    let modes: any = [];
    if (ShipmentsData && ShipmentsData.modes) {
      modes = ShipmentsData.modes;
    }

    let hubs: any = [];
    if (ShipmentsData && ShipmentsData.transports[0]) {
      hubs =
        ShipmentsData.transports[0].hubs && ShipmentsData.transports[0].hubs;
    }

    const { _id, name, origin, destination, containerNumber, status } =
      this.props.shipment;

    const isOngoingShipment = status && status === "ongoing";
    const sharingCompany = getSharingCompany(this.props.shipment);
    const tags = [...this.props.shipment.tags];

    sharingCompany &&
      tags.unshift({ name: sharingCompany, styles: { color: "#7e3da0" } });
    const { expanded } = this.state;
    const shipmentDate = new Date(ShipmentsData && ShipmentsData.createdAt);
    const createdDate = `${shipmentDate.getDate()}.${
      shipmentDate.getMonth() + 1
    }.${shipmentDate.getFullYear()}`;

    const transports = ShipmentsData && ShipmentsData.transports;

    let estimatedDate: string | null = null;

    if (transports && transports[0] && transports[0].hubs) {
      const hubs = transports[0].hubs;
      const lastArrayIndex = hubs.length - 1; // Get the index of the last element
      const etaTimestamp = hubs[lastArrayIndex]
        ? hubs[lastArrayIndex].arrivalEst
        : "NA"; // Get the ETA timestamp from the last array element

      if (etaTimestamp) {
        const etaDate = new Date(etaTimestamp); // Convert the timestamp to a Date object
        estimatedDate = `${etaDate.getDate()}.${
          etaDate.getMonth() + 1
        }.${etaDate.getFullYear()}`; // Format the date as dd.mm.yyyy
      }
    }
    console.log(
      ShipmentsData,
      hubs,
      emissionStats,
      "I am shipment data from postman"
    );
    const firstWaypoints =
      (ShipmentsData &&
        ShipmentsData.transports &&
        ShipmentsData.transports[0] &&
        ShipmentsData.transports[0].measurements) ||
      [];

    // Sort the measurements based on the time property
    firstWaypoints.sort(
      (a: any, b: any) =>
        new Date(a.time).getTime() - new Date(b.time).getTime()
    );
    const currentLocation =
      firstWaypoints.length > 0
        ? firstWaypoints[firstWaypoints.length - 1].location
        : null;

    const fields =
      ShipmentsData &&
      ShipmentsData.inspections &&
      ShipmentsData.inspections[0] &&
      ShipmentsData.inspections[0].fields &&
      ShipmentsData.inspections[0].fields;
    const battery =
      ShipmentsData &&
      ShipmentsData.transports &&
      ShipmentsData.transports[0] &&
      ShipmentsData.transports[0].tracker &&
      ShipmentsData.transports[0].tracker.lastMeasurement &&
      ShipmentsData.transports[0].tracker.lastMeasurement.battery &&
      ShipmentsData.transports[0].tracker.lastMeasurement.battery;

    const temperature =
      ShipmentsData &&
      ShipmentsData.transports &&
      ShipmentsData.transports[0] &&
      ShipmentsData.transports[0].tracker &&
      ShipmentsData.transports[0].tracker.lastMeasurement &&
      ShipmentsData.transports[0].tracker.lastMeasurement.temperature &&
      ShipmentsData.transports[0].tracker.lastMeasurement.temperature;
    console.log(currentLocation, "card");
    const dateObject = new Date(
      ShipmentsData && ShipmentsData.updatedAt && ShipmentsData.updatedAt
    );

    // Format the date as a readable string
    const formattedDate = dateObject.toLocaleString();
    return (
      <>
        {loading ? (
          <>loading</>
        ) : (
          <>
            {expanded && this.state.isVisible ? (
              <div
                className="shipment-card__container expanded"
                style={{
                  margin: "0px",
                  maxWidth: isMobile ? "" : "130%",
                  height: isMobile ? "" : "",
                }}
              >
                <Link
                  className="shlk-card shipment-card__box"
                  key={_id}
                  to={`/shipments/${_id}`}
                >
                  <div
                    className="shipment-card__top"
                    style={{
                      padding: "15px",
                      overflowY: isMobile ? "scroll" : "unset",
                    }}
                  >
                    <div className="shipment-card__info mobile-width">
                      <div
                        className="shipment-card__field left name"
                        style={
                          isMobile
                            ? {
                                justifyContent: "space-between",
                                marginBottom: "30px",
                              }
                            : { justifyContent: "space-between" }
                        }
                      >
                         {ShipmentsData.name
                        ? ShipmentsData.name.length > 30
                          ? `${ShipmentsData.name.slice(0, 30)}...`
                          : ShipmentsData.name.toUpperCase()
                        : ""}
                        <span
                          className={` ${
                            this.props.showBoxButton &&
                            this.props.actions &&
                            "container-number"
                          }`}
                          style={{ marginRight: "0px" }}
                        >
                          Container No.
                          {ShipmentsData.containerNumber
                          ? ShipmentsData.containerNumber.length < 10
                            ? ShipmentsData.containerNumber
                            : `${ShipmentsData.containerNumber.slice(0, 9)}...`
                          : "NA"}
                        </span>
                        {isMobile &&
                          this.props.showBoxButton &&
                          this.props.actions && (
                            <div
                              className="show-more"
                              style={{ display: "contents" }}
                            >
                              <>
                                <ReactSVG
                                  style={{
                                    color: "black",
                                    marginRight: "23px",
                                  }}
                                  src={"/assets/svg/minimise.svg"}
                                  onClick={this.toggleExpanded}
                                />
                              </>
                            </div>
                          )}
                      </div>
                      {!isMobile && (
                        <div
                          className="shipment-card__field right name"
                          style={isMobile ? { justifyContent: "end" } : {}}
                        >
                          {this.props.showBoxButton && this.props.actions && (
                            <div
                              className="show-more"
                              style={{ display: "contents" }}
                            >
                              <>
                                <ReactSVG
                                  style={{
                                    color: "black",
                                    marginRight: "23px",
                                  }}
                                  src={"/assets/svg/minimise.svg"}
                                  onClick={this.toggleExpanded}
                                />
                              </>
                            </div>
                          )}
                        </div>
                      )}

                      {isMobile && (
                        <>
                          <div
                            className="shipment-card__bar full_width"
                            style={{
                              marginTop: "20px",
                              marginBottom: "20px",
                              marginLeft: "10px",
                            }}
                          >
                            <div className="shipment-bar">
                              {hubs.map((hub: any, index: any) => (
                                <React.Fragment key={hub._id}>
                                  {/* Render point */}
                                  <div
                                    className={`shipment-bar__point expanded ${
                                      hub.arrivalAct && "done"
                                    } `}
                                    style={{
                                      left: `${
                                        (index / (hubs.length - 1)) * 100
                                      }%`,
                                      zIndex: "2",
                                    }}
                                  >
                                    <div className="shipment-container">
                                      <div
                                        className="shipment-type"
                                        style={{
                                          position:
                                            hub.type !== "SHIPMENT_DESTINATION"
                                              ? "relative"
                                              : undefined,
                                          transform: "translateX(-100%)",
                                        }}
                                      >
                                        <span style={{ marginLeft: "5px" }}>
                                          {hub.address.address}
                                        </span>
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "240%",
                                            marginTop: "10px",
                                            left:
                                              hub.type ===
                                              "SHIPMENT_DESTINATION"
                                                ? undefined
                                                : "0%",
                                            right:
                                              hub.type ===
                                              "SHIPMENT_DESTINATION"
                                                ? "0%"
                                                : undefined,
                                          }}
                                        ></span>
                                      </div>
                                      <div className="shipment-point"></div>
                                    </div>
                                  </div>

                                  {/* Render line if there is a next mode */}
                                  {index < modes.length && (
                                    <div
                                      className={`shipment-bar__line expanded ${
                                        modes[index].status === "COMPLETED"
                                          ? "done"
                                          : ""
                                      } ${
                                        modes[index].status === "ONGOING"
                                          ? "ongoing"
                                          : ""
                                      }`}
                                      style={{
                                        position: "absolute",
                                        left: `${
                                          (index / (hubs.length - 1)) * 100
                                        }%`,
                                        top: "1px",
                                        width: `${
                                          (1 / (hubs.length - 1)) * 100
                                        }%`,
                                      }}
                                    >
                                      {/* Render progress for the ongoing segment */}
                                      {modes[index].status === "ONGOING" && (
                                        <div
                                          className="shipment-bar__progress"
                                          style={{
                                            position: "absolute",
                                            width: `${
                                              hubs[index] && hubs[index + 1]
                                                ? calculatePercentageDone(
                                                    hubs[index].address.location
                                                      .lat, // originLat
                                                    hubs[index].address.location
                                                      .lng, // originLng
                                                    hubs[index + 1].address
                                                      .location.lat, // destLat
                                                    hubs[index + 1].address
                                                      .location.lng, // destLng (should be lng)
                                                    currentLocation.lat, // actualLat
                                                    currentLocation.lng // actualLng
                                                  )
                                                : "0"
                                            }%`,
                                            zIndex: "1",
                                            height: "3px",
                                            // top: "1px",
                                            backgroundColor: "#19575C",
                                          }}
                                        >
                                          {modes[index].modeType === "Land" &&
                                            isOngoingShipment && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/truck.svg`}
                                                />
                                              </div>
                                            )}
                                          {modes[index].modeType === "Air" &&
                                            isOngoingShipment && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/flight.svg`}
                                                />
                                              </div>
                                            )}
                                          {modes[index].modeType === "Sea" &&
                                            isOngoingShipment && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/ship.svg`}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      )}
                                      {modes[index].status === "COMPLETED" &&
                                        index === modes.length - 1 && (
                                          <div
                                            className="shipment-bar__progress"
                                            style={{
                                              position: "absolute",
                                              width: `${
                                                hubs[index] && hubs[index + 1]
                                                  ? calculatePercentageDone(
                                                      hubs[index].address
                                                        .location.lat, // originLat
                                                      hubs[index].address
                                                        .location.lng, // originLng
                                                      hubs[index + 1].address
                                                        .location.lat, // destLat
                                                      hubs[index + 1].address
                                                        .location.lng, // destLng (should be lng)
                                                      currentLocation.lat, // actualLat
                                                      currentLocation.lng // actualLng
                                                    )
                                                  : "0"
                                              }%`,
                                              zIndex: "5",
                                              height: "3px",
                                              // top: "1px",
                                              backgroundColor: "#19575C",
                                            }}
                                          >
                                            {modes[index].modeType ===
                                              "Land" && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/truck.svg`}
                                                />
                                              </div>
                                            )}
                                            {modes[index].modeType ===
                                              "Air" && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/flight.svg`}
                                                />
                                              </div>
                                            )}
                                            {modes[index].modeType ===
                                              "Sea" && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/ship.svg`}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      {!isOngoingShipment &&
                                        index === modes.length - 1 && (
                                          <div
                                            style={{
                                              zIndex: "3",
                                              position: "relative",
                                            }}
                                          >
                                            {modes[index].modeType === "Land" &&
                                              index === modes.length - 1 && (
                                                <div
                                                  className="shipment-bar__icon expanded"
                                                  style={{
                                                    position: "absolute",
                                                    left: "105%", // Move to the end of the bar
                                                    transform:
                                                      "translateX(-100%)", // Prevent overflow
                                                    backgroundColor: "#8b8b8b",
                                                  }}
                                                >
                                                  <ReactSVG
                                                    src={`/assets/svg/truck.svg`}
                                                  />
                                                </div>
                                              )}
                                            {modes[index].modeType === "Air" &&
                                              index === modes.length - 1 && (
                                                <div
                                                  className="shipment-bar__icon expanded"
                                                  style={{
                                                    position: "absolute",
                                                    left: "105%", // Move to the end of the bar
                                                    transform:
                                                      "translateX(-100%)", // Prevent overflow
                                                    backgroundColor: "#8b8b8b",
                                                  }}
                                                >
                                                  <ReactSVG
                                                    src={`/assets/svg/flight.svg`}
                                                  />
                                                </div>
                                              )}
                                            {modes[index].modeType === "Sea" &&
                                              index === modes.length - 1 && (
                                                <div
                                                  className="shipment-bar__icon expanded"
                                                  style={{
                                                    position: "absolute",
                                                    left: "105%", // Move to the end of the bar
                                                    transform:
                                                      "translateX(-100%)", // Prevent overflow
                                                    backgroundColor: "#8b8b8b",
                                                  }}
                                                >
                                                  <ReactSVG
                                                    src={`/assets/svg/ship.svg`}
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                              <div
                                className="shipment-bar__point expanded"
                                style={{ left: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </>
                      )}

                      {!isMobile && (
                        <>
                          <div
                            className="shipment-card__bar full_width"
                            style={{
                              marginTop: "30px",
                              marginBottom: "45px",
                              marginLeft: "5px",
                              marginRight: "5px",
                            }}
                          >
                            <div className="shipment-bar">
                              {hubs.map((hub: any, index: any) => (
                                <React.Fragment key={hub._id}>
                                  {/* Render point */}
                                  <div
                                    className={`shipment-bar__point expanded ${
                                      hub.arrivalAct && "done"
                                    } `}
                                    style={{
                                      left: `${
                                        (index / (hubs.length - 1)) * 100
                                      }%`,
                                      zIndex: "2",
                                    }}
                                  >
                                    <div className="shipment-container">
                                      <div
                                        className="shipment-type"
                                        style={{
                                          position:
                                            hub.type !== "SHIPMENT_DESTINATION"
                                              ? "relative"
                                              : undefined,
                                          transform: "translateX(-100%)",
                                        }}
                                      >
                                        <span style={{ marginLeft: "10px" }}>
                                          {hub.address.address}
                                        </span>
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "240%",
                                            marginTop: "15px",
                                            marginLeft: "10px",
                                            left:
                                              hub.type ===
                                              "SHIPMENT_DESTINATION"
                                                ? undefined
                                                : "0%",
                                            right:
                                              hub.type ===
                                              "SHIPMENT_DESTINATION"
                                                ? "0%"
                                                : undefined,
                                          }}
                                        >
                                          {hub.arrivalAct
                                            ? new Date(
                                                hub.arrivalAct
                                              ).toLocaleTimeString("en-US", {
                                                hour12: true,
                                                hour: "numeric",
                                                minute: "numeric",
                                              })
                                            : new Date(
                                                hub.arrivalEst
                                              ).toLocaleTimeString("en-US", {
                                                hour12: true,
                                                hour: "numeric",
                                                minute: "numeric",
                                              })}
                                        </span>
                                      </div>
                                      <div className="shipment-point"></div>
                                    </div>
                                  </div>

                                  {/* Render line if there is a next mode */}
                                  {index < modes.length && (
                                    <div
                                      className={`shipment-bar__line expanded ${
                                        modes[index].status === "COMPLETED"
                                          ? "done"
                                          : ""
                                      } ${
                                        modes[index].status === "ONGOING"
                                          ? "ongoing"
                                          : ""
                                      }`}
                                      style={{
                                        position: "absolute",
                                        left: `${
                                          (index / (hubs.length - 1)) * 100
                                        }%`,
                                        top: "1px",
                                        width: `${
                                          (1 / (hubs.length - 1)) * 100
                                        }%`,
                                      }}
                                    >
                                      {/* Render progress for the ongoing segment */}
                                      {modes[index].status === "ONGOING" && (
                                        <div
                                          className="shipment-bar__progress"
                                          style={{
                                            position: "absolute",
                                            width: `${
                                              hubs[index] && hubs[index + 1]
                                                ? calculatePercentageDone(
                                                    hubs[index].address.location
                                                      .lat, // originLat
                                                    hubs[index].address.location
                                                      .lng, // originLng
                                                    hubs[index + 1].address
                                                      .location.lat, // destLat
                                                    hubs[index + 1].address
                                                      .location.lng, // destLng (should be lng)
                                                    currentLocation.lat, // actualLat
                                                    currentLocation.lng // actualLng
                                                  )
                                                : "0"
                                            }%`,
                                            zIndex: "1",
                                            height: "3px",
                                            // top: "1px",
                                            backgroundColor: "#19575C",
                                          }}
                                        >
                                          {modes[index].modeType === "Land" &&
                                            isOngoingShipment && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/truck.svg`}
                                                />
                                              </div>
                                            )}
                                          {modes[index].modeType === "Air" &&
                                            isOngoingShipment && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/flight.svg`}
                                                />
                                              </div>
                                            )}
                                          {modes[index].modeType === "Sea" &&
                                            isOngoingShipment && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/ship.svg`}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      )}
                                      {modes[index].status === "COMPLETED" &&
                                        index === modes.length - 1 && (
                                          <div
                                            className="shipment-bar__progress"
                                            style={{
                                              position: "absolute",
                                              width: `${
                                                hubs[index] && hubs[index + 1]
                                                  ? calculatePercentageDone(
                                                      hubs[index].address
                                                        .location.lat, // originLat
                                                      hubs[index].address
                                                        .location.lng, // originLng
                                                      hubs[index + 1].address
                                                        .location.lat, // destLat
                                                      hubs[index + 1].address
                                                        .location.lng, // destLng (should be lng)
                                                      currentLocation.lat, // actualLat
                                                      currentLocation.lng // actualLng
                                                    )
                                                  : "0"
                                              }%`,
                                              zIndex: "5",
                                              height: "3px",
                                              // top: "1px",
                                              backgroundColor: "#19575C",
                                            }}
                                          >
                                            {modes[index].modeType ===
                                              "Land" && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/truck.svg`}
                                                />
                                              </div>
                                            )}
                                            {modes[index].modeType ===
                                              "Air" && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/flight.svg`}
                                                />
                                              </div>
                                            )}
                                            {modes[index].modeType ===
                                              "Sea" && (
                                              <div className="shipment-bar__icon expanded">
                                                <ReactSVG
                                                  src={`/assets/svg/ship.svg`}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      {!isOngoingShipment &&
                                        index === modes.length - 1 && (
                                          <div
                                            style={{
                                              zIndex: "6",
                                              position: "relative",
                                            }}
                                          >
                                            {modes[index].modeType === "Land" &&
                                              index === modes.length - 1 && (
                                                <div
                                                  className="shipment-bar__icon expanded"
                                                  style={{
                                                    position: "absolute",
                                                    left: "101%", // Move to the end of the bar
                                                    transform:
                                                      "translateX(-100%)", // Prevent overflow
                                                    backgroundColor: "#8b8b8b",
                                                  }}
                                                >
                                                  <ReactSVG
                                                    src={`/assets/svg/truck.svg`}
                                                  />
                                                </div>
                                              )}
                                            {modes[index].modeType === "Air" &&
                                              index === modes.length - 1 && (
                                                <div
                                                  className="shipment-bar__icon expanded"
                                                  style={{
                                                    position: "absolute",
                                                    left: "101%", // Move to the end of the bar
                                                    transform:
                                                      "translateX(-100%)", // Prevent overflow
                                                    backgroundColor: "#8b8b8b",
                                                  }}
                                                >
                                                  <ReactSVG
                                                    src={`/assets/svg/flight.svg`}
                                                  />
                                                </div>
                                              )}
                                            {modes[index].modeType === "Sea" &&
                                              index === modes.length - 1 && (
                                                <div
                                                  className="shipment-bar__icon expanded"
                                                  style={{
                                                    position: "absolute",
                                                    left: "101%", // Move to the end of the bar
                                                    transform:
                                                      "translateX(-100%)", // Prevent overflow
                                                    backgroundColor: "#8b8b8b",
                                                  }}
                                                >
                                                  <ReactSVG
                                                    src={`/assets/svg/ship.svg`}
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        )}
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                              <div
                                className="shipment-bar__point expanded"
                                style={{ left: "100%" }}
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                      {!isMobile && (
                        <div
                          className="shipment-card__bar full_width"
                          style={{
                            display: isMobile ? "flex" : "",
                            flexDirection: isMobile ? "column" : "row",
                          }}
                        >
                          {" "}
                          <div
                            style={{
                              width: isMobile ? "100%" : "440px",
                              maxWidth: isMobile ? "" : "33%",
                              height: "300px",
                              backgroundColor: "#e8eeef",
                              // marginBottom: "10px",
                              position: "relative",
                              // height: "200px",
                              borderRadius: "10px",
                              marginBottom: isMobile ? "5px" : "",
                            }}
                          >
                            {ShipmentsData &&
                            ShipmentsData.inspections &&
                            ShipmentsData.inspections[0] ? (
                              <>
                                {" "}
                                <div
                                  onClick={this.prevSlide}
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    top: "50%",
                                    left: "10px",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    zIndex: 1,
                                    background: "white",
                                    color: "#19575c",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50px",
                                    padding: "px",
                                    flexDirection: "column",
                                  }}
                                >
                                  {"<"}
                                </div>
                                {currentSlide === 0 && (
                                  <img
                                    src={fields[0].images[0]}
                                    alt={`Inspection `}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                                {currentSlide === 1 && (
                                  <img
                                    src={fields[1].images[0]}
                                    alt={`Inspection `}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                                {currentSlide === 2 && (
                                  <img
                                    src={fields[2].images[0]}
                                    alt={`Inspection `}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                                {currentSlide === 3 && (
                                  <img
                                    src={fields[3].images[0]}
                                    alt={`Inspection `}
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                  />
                                )}
                                <div
                                  onClick={this.nextSlide}
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    position: "absolute",
                                    top: "50%",
                                    right: "10px",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    zIndex: 1,

                                    background: "white",
                                    color: "#19575c",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50px",
                                    padding: "px",
                                    flexDirection: "column",
                                  }}
                                >
                                  {">"}
                                </div>
                              </>
                            ) : (
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                }}
                              >
                                No Inspections Yet
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              width: isMobile ? "100%" : "33%",
                              height: isMobile ? "100%" : "300px",
                              backgroundColor: "#e8eeef",
                              borderRadius: "10px",
                              display: "flex",
                              flexDirection: "column",
                              // padding:"10px"
                              marginBottom: isMobile ? "5px" : "",
                            }}
                          >
                            {" "}
                            <div style={{ height: "90%", overflowY: "auto" }}>
                              <div style={{ margin: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  Warnings
                                </span>
                                <div
                                  style={{
                                    fontWeight: "normal",
                                    whiteSpace: "normal",
                                    // marginLeft: "40px",
                                  }}
                                >
                                  {ShipmentsData &&
                                    ShipmentsData.transports &&
                                    ShipmentsData.transports[0] &&
                                    ShipmentsData.transports[0].events.map(
                                      (event: any, index: any) => (
                                        <div key={index}>
                                          {event.ack === false &&
                                            (event.type === "DELAY" ||
                                              event.type === "BATTERY" ||
                                              event.type === "LIGHT" ||
                                              event.type === "TEMPERATURE") && (
                                              <>
                                                <span>
                                                  {event.message &&
                                                    event.message + "*"}
                                                </span>
                                              </>
                                            )}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                              <div style={{ margin: "10px" }}>
                                <div style={{ fontWeight: "bold" }}>
                                  Last Events
                                </div>
                                <div
                                  style={{
                                    fontWeight: "normal",
                                    whiteSpace: "normal",
                                    // marginLeft: "40px",
                                  }}
                                >
                                  {ShipmentsData &&
                                    ShipmentsData.transports &&
                                    ShipmentsData.transports[0] &&
                                    ShipmentsData.transports[0].events.map(
                                      (event: any, index: any) => (
                                        <div key={index}>
                                          {event.ack === true &&
                                            event.type !== "DELAY" &&
                                            event.type !== "BATTERY" &&
                                            event.type !== "LIGHT" &&
                                            event.type !== "TEMPERATURE" && (
                                              <>
                                                <span>{event.message}</span>
                                              </>
                                            )}
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                            <div style={{ margin: "10px", height: "10%" }}>
                              <span style={{ fontWeight: "bold" }}>
                                * Delays are rounded to the nearest hour.
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              width: isMobile ? "100%" : "33%",
                              height: "300px",
                              backgroundColor: "#e8eeef",
                              display: "flex",
                              flexDirection: "column",
                              borderRadius: "10px",
                              marginBottom: isMobile ? "5px" : "",
                            }}
                          >
                            <div
                              style={{
                                height: "90%",
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "white",
                                  boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                  // width: "23%",
                                  margin: "10px 5px 5px 10px",
                                  // marginBottom: "15px",
                                  display: "flex",
                                  flexDirection: "column",
                                  // borderRadius: "5px",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  justifyContent: "space-evenly",
                                  borderRadius: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    textAlign: "center",
                                    height: isMobile ? "40%" : "",
                                  }}
                                >
                                  Total Emissions until now
                                </span>
                                {/* <br></br> */}
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <div>
                                    {emissionStats.emissions.totalDynamic
                                      ? emissionStats.emissions.totalDynamic.toFixed(
                                          2
                                        )
                                      : "NA"}{" "}
                                    /{" "}
                                    <span style={{ color: "#8B8B8B" }}>
                                      {emissionStats.emissions.totalStatic
                                        ? emissionStats.emissions.totalStatic.toFixed(
                                            2
                                          )
                                        : "NA"}
                                    </span>
                                  </div>
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "normal",
                                      color: "#3D9AA1",
                                    }}
                                  >
                                    kg
                                  </span>
                                </span>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                  // width: "23%",
                                  margin: "10px 10px 5px 5px",
                                  // marginBottom: "15px",
                                  display: "flex",
                                  flexDirection: "column",
                                  // borderRadius: "5px",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  justifyContent: "space-evenly",
                                  borderRadius: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    textAlign: "center",
                                    height: isMobile ? "40%" : "",
                                  }}
                                >
                                  Emissions per transport activity
                                </span>
                                {/* <br></br> */}
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  <div>
                                    {emissionStats.emissions.dynamicCO2tkm
                                      ? emissionStats.emissions.dynamicCO2tkm.toFixed(
                                          2
                                        )
                                      : "NA"}{" "}
                                    /{" "}
                                    <span style={{ color: "#8B8B8B" }}>
                                      {emissionStats.emissions.staticCO2tkm
                                        ? emissionStats.emissions.staticCO2tkm.toFixed(
                                            2
                                          )
                                        : "NA"}
                                    </span>
                                  </div>

                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "normal",
                                      color: "#3D9AA1",
                                    }}
                                  >
                                    kg/tkm
                                  </span>
                                </span>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                  // width: "23%",
                                  margin: "5px 5px 10px 10px",
                                  // marginBottom: "15px",
                                  display: "flex",
                                  flexDirection: "column",
                                  // borderRadius: "5px",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  justifyContent: "space-evenly",
                                  borderRadius: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    textAlign: "center",
                                    height: isMobile ? "40%" : "",
                                  }}
                                >
                                  Container Emission intensity
                                </span>
                                {/* <br></br> */}
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  <div>
                                    {emissionStats.emissions.dynamicTotalTEU
                                      ? emissionStats.emissions.dynamicTotalTEU.toFixed(
                                          2
                                        )
                                      : "NA"}{" "}
                                    /{" "}
                                    <span style={{ color: "#8B8B8B" }}>
                                      {emissionStats.emissions.staticTotalTEU
                                        ? emissionStats.emissions.staticTotalTEU.toFixed(
                                            2
                                          )
                                        : "NA"}
                                    </span>
                                  </div>
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "normal",
                                      color: "#3D9AA1",
                                    }}
                                  >
                                    kg/teu
                                  </span>
                                </span>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  boxShadow: "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                  // width: "23%",
                                  margin: "5px 10px 10px 5px",
                                  // marginBottom: "15px",
                                  display: "flex",
                                  flexDirection: "column",
                                  // borderRadius: "5px",
                                  paddingTop: "10px",
                                  paddingBottom: "10px",
                                  justifyContent: "space-evenly",
                                  borderRadius: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    textAlign: "center",
                                    height: isMobile ? "40%" : "",
                                  }}
                                >
                                  Emissions per weight
                                </span>
                                {/* <br></br> */}
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "#19575C",
                                    // paddingLeft: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  <div>
                                    {emissionStats.emissions.dynamicCO2Kg
                                      ? emissionStats.emissions.dynamicCO2Kg.toFixed(
                                          2
                                        )
                                      : "NA"}{" "}
                                    /{" "}
                                    <span style={{ color: "#8B8B8B" }}>
                                      {emissionStats.emissions.staticCO2Kg
                                        ? emissionStats.emissions.staticCO2Kg.toFixed(
                                            2
                                          )
                                        : "NA"}
                                    </span>
                                  </div>

                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "normal",
                                      color: "#3D9AA1",
                                    }}
                                  >
                                    kg
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div style={{ height: "10%" }}>
                              {" "}
                              <span style={{ marginLeft: "10px" }}>
                                <span
                                  className="dynamic-data"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  GLEC DYNAMIC /
                                </span>
                                <span
                                  className="static-data"
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "bold",
                                    color: "#8B8B8B",
                                  }}
                                >
                                  {" "}
                                  GLEC DEFAULT
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {isMobile && (
                        <div
                          className="scrollable-content expanded-line-chart shipment-card__bar full_width"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "60vh",
                            marginBottom: "0px",
                            marginTop: "5px",
                            width: "100%",
                          }}
                        >
                          <div
                            className="shipment-card__bar full_width"
                            style={{
                              display: isMobile ? "flex" : "",
                              flexDirection: isMobile ? "column" : "row",
                              marginBottom: isMobile ? "0px" : "",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                width: isMobile ? "100%" : "33%",
                                height: "300px",
                                backgroundColor: "#e8eeef",
                                // marginBottom: "10px",
                                position: "relative",
                                // height: "200px",
                                borderRadius: "10px",
                                marginBottom: isMobile ? "5px" : "",
                              }}
                            >
                              {ShipmentsData &&
                              ShipmentsData.inspections &&
                              ShipmentsData.inspections[0] ? (
                                <>
                                  {" "}
                                  <div
                                    onClick={this.prevSlide}
                                    style={{
                                      fontSize: "23px",
                                      fontWeight: "bold",
                                      position: "absolute",
                                      top: "50%",
                                      left: "10px",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                      zIndex: 1,

                                      background: "white",
                                      color: "#19575c",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50px",
                                      padding: "1px",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {"<"}
                                  </div>
                                  {currentSlide === 0 && (
                                    <img
                                      src={fields[0].images[0]}
                                      alt={`Inspection `}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                  {currentSlide === 1 && (
                                    <img
                                      src={fields[1].images[0]}
                                      alt={`Inspection `}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                  {currentSlide === 2 && (
                                    <img
                                      src={fields[2].images[0]}
                                      alt={`Inspection `}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                  {currentSlide === 3 && (
                                    <img
                                      src={fields[3].images[0]}
                                      alt={`Inspection `}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  )}
                                  <div
                                    onClick={this.nextSlide}
                                    style={{
                                      fontSize: "23px",
                                      fontWeight: "bold",
                                      position: "absolute",
                                      top: "50%",
                                      right: "10px",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                      zIndex: 1,

                                      background: "white",
                                      color: "#19575c",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      borderRadius: "50px",
                                      padding: "1px",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {">"}
                                  </div>
                                </>
                              ) : (
                                <span
                                  className="shipment-card__bar full_width"
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  No Inspections Yet
                                </span>
                              )}
                            </div>
                            <div
                              style={{
                                width: isMobile ? "100%" : "33%",
                                height: isMobile ? "" : "300px",
                                backgroundColor: "#e8eeef",
                                borderRadius: "10px",
                                display: "flex",
                                flexDirection: "column",
                                // padding:"10px"
                                marginBottom: isMobile ? "5px" : "",
                              }}
                            >
                              {" "}
                              <div
                                style={{
                                  height: isMobile ? "" : "90%",
                                  overflowY: "auto",
                                }}
                              >
                                <div style={{ margin: "10px" }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Warnings
                                  </span>
                                  <div
                                    style={{
                                      fontWeight: "normal",
                                      whiteSpace: "normal",
                                      margin: "10px 0px",
                                    }}
                                  >
                                    {ShipmentsData &&
                                      ShipmentsData.transports &&
                                      ShipmentsData.transports[0] &&
                                      ShipmentsData.transports[0].events.map(
                                        (event: any, index: any) => (
                                          <div key={index}>
                                            {event.ack === false &&
                                              (event.type === "DELAY" ||
                                                event.type === "BATTERY" ||
                                                event.type === "LIGHT" ||
                                                event.type ===
                                                  "TEMPERATURE") && (
                                                <>
                                                  <span>
                                                    {event.message &&
                                                      event.message + "*"}
                                                  </span>
                                                </>
                                              )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                                <div style={{ margin: "10px" }}>
                                  <div style={{ fontWeight: "bold" }}>
                                    Last Events
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: "normal",
                                      whiteSpace: "normal",
                                      margin: "10px 0px",
                                    }}
                                  >
                                    {ShipmentsData &&
                                      ShipmentsData.transports &&
                                      ShipmentsData.transports[0] &&
                                      ShipmentsData.transports[0].events.map(
                                        (event: any, index: any) => (
                                          <div key={index}>
                                            {event.ack === true &&
                                              event.type !== "DELAY" &&
                                              event.type !== "BATTERY" &&
                                              event.type !== "LIGHT" &&
                                              event.type !== "TEMPERATURE" && (
                                                <>
                                                  <span>{event.message}</span>
                                                </>
                                              )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div style={{ margin: "10px", height: "10%" }}>
                                <span style={{ fontWeight: "bold" }}>
                                  * Delays are rounded to the nearest hour.
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                width: isMobile ? "100%" : "33%",
                                height: "300px",
                                backgroundColor: "#e8eeef",
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: "10px",
                                marginBottom: isMobile ? "5px" : "",
                              }}
                            >
                              <div
                                style={{
                                  height: "90%",
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                    // width: "23%",
                                    margin: "10px 5px 5px 10px",
                                    // marginBottom: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    // borderRadius: "5px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    justifyContent: "space-evenly",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      textAlign: "center",
                                      height: isMobile ? "40%" : "",
                                    }}
                                  >
                                    Total Emissions until now
                                  </span>
                                  {/* <br></br> */}
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                    }}
                                  >
                                    {" "}
                                    <div>
                                      {emissionStats.emissions.totalDynamic
                                        ? emissionStats.emissions.totalDynamic.toFixed(
                                            2
                                          )
                                        : "NA"}{" "}
                                      /{" "}
                                      <span style={{ color: "#8B8B8B" }}>
                                        {emissionStats.emissions.totalStatic
                                          ? emissionStats.emissions.totalStatic.toFixed(
                                              2
                                            )
                                          : "NA"}
                                      </span>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "normal",
                                        color: "#3D9AA1",
                                      }}
                                    >
                                      kg
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                    // width: "23%",
                                    margin: "10px 10px 5px 5px",
                                    // marginBottom: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    // borderRadius: "5px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    justifyContent: "space-evenly",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      textAlign: "center",
                                      height: isMobile ? "40%" : "",
                                    }}
                                  >
                                    Emissions per transport activity
                                  </span>
                                  {/* <br></br> */}
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div>
                                      {emissionStats.emissions.dynamicCO2tkm
                                        ? emissionStats.emissions.dynamicCO2tkm.toFixed(
                                            2
                                          )
                                        : "NA"}{" "}
                                      /{" "}
                                      <span style={{ color: "#8B8B8B" }}>
                                        {emissionStats.emissions.staticCO2tkm
                                          ? emissionStats.emissions.staticCO2tkm.toFixed(
                                              2
                                            )
                                          : "NA"}
                                      </span>
                                    </div>

                                    <span
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "normal",
                                        color: "#3D9AA1",
                                      }}
                                    >
                                      kg/tkm
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                    // width: "23%",
                                    margin: "5px 5px 10px 10px",
                                    // marginBottom: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    // borderRadius: "5px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    justifyContent: "space-evenly",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      textAlign: "center",
                                      height: isMobile ? "40%" : "",
                                    }}
                                  >
                                    Container Emission intensity
                                  </span>
                                  {/* <br></br> */}
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div>
                                      {emissionStats.emissions.dynamicTotalTEU
                                        ? emissionStats.emissions.dynamicTotalTEU.toFixed(
                                            2
                                          )
                                        : "NA"}{" "}
                                      /{" "}
                                      <span style={{ color: "#8B8B8B" }}>
                                        {emissionStats.emissions.staticTotalTEU
                                          ? emissionStats.emissions.staticTotalTEU.toFixed(
                                              2
                                            )
                                          : "NA"}
                                      </span>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "normal",
                                        color: "#3D9AA1",
                                      }}
                                    >
                                      kg/teu
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "white",
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.12) 0px 4px 16px",
                                    // width: "23%",
                                    margin: "5px 10px 10px 5px",
                                    // marginBottom: "15px",
                                    display: "flex",
                                    flexDirection: "column",
                                    // borderRadius: "5px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    justifyContent: "space-evenly",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      textAlign: "center",
                                      height: isMobile ? "40%" : "",
                                    }}
                                  >
                                    Emissions per weight
                                  </span>
                                  {/* <br></br> */}
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                      color: "#19575C",
                                      // paddingLeft: "10px",
                                      display: "flex",
                                      flexDirection: "column",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div>
                                      {emissionStats.emissions.dynamicCO2Kg
                                        ? emissionStats.emissions.dynamicCO2Kg.toFixed(
                                            2
                                          )
                                        : "NA"}{" "}
                                      /{" "}
                                      <span style={{ color: "#8B8B8B" }}>
                                        {emissionStats.emissions.staticCO2Kg
                                          ? emissionStats.emissions.staticCO2Kg.toFixed(
                                              2
                                            )
                                          : "NA"}
                                      </span>
                                    </div>

                                    <span
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "normal",
                                        color: "#3D9AA1",
                                      }}
                                    >
                                      kg
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div style={{ height: "10%" }}>
                                {" "}
                                <span style={{ marginLeft: "10px" }}>
                                  <span
                                    className="dynamic-data"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    GLEC DYNAMIC /
                                  </span>
                                  <span
                                    className="static-data"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                      color: "#8B8B8B",
                                    }}
                                  >
                                    {" "}
                                    GLEC DEFAULT
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!isMobile && (
                        <div
                          className="shipment-card__bar shipment-card__field left info-btn-wrapper bc-wrapper expanded"
                          style={{
                            flexDirection: "row",
                            marginBottom: "0px",
                          }}
                        ></div>
                      )}

                      {this.props.showBoxButton &&
                      isOngoingShipment &&
                      !this.props.isPublic &&
                      this.props.actions ? (
                        <div
                          className="shipment-card__bar shipment-card__field right info-btn-wrapper bc-wrapper expanded"
                          style={{
                            flexDirection: "row",
                            marginBottom: "0px",
                            width: isMobile ? "" : "",
                            marginTop: isMobile ? "10px" : "",
                            display: isMobile ? "flex" : "",
                            justifyContent: isMobile ? "space-between" : "",
                          }}
                        >
                          <span
                            style={{ border: "transparent", display: "none" }}
                          ></span>
                          {!isMobile && (
                            <button
                              style={{
                                padding: "5px 12px",
                                borderRadius: "5px",
                                border: "2px solid #3d9aa1",
                                // marginBottom: "10px",
                                fontSize: "13px",
                                fontWeight: "600",
                                cursor: "pointer",
                                width: "100px",
                                display: "flex",
                                justifyContent: "center",
                                height: "32px",
                                alignItems: "center",
                              }}
                              className="tags-manager"
                              onClick={this.props.openTagTab}
                            >
                              {" "}
                              <ReactSVG
                                className="tags-manager__icon"
                                src="/assets/svg/pricetags-outline.svg"
                              />
                              <span style={{ fontSize: "16px" }}>Tags</span>
                            </button>
                          )}

                          <BoxButton
                            category="ShipmentInfo"
                            action="Click Cancel"
                            label={_id}
                            onClick={this.delete}
                            icon="close"
                            text="Cancel"
                          />
                          <BoxButton
                            category="ShipmentInfo"
                            action="Click Finish"
                            label={_id}
                            onClick={this.complete}
                            icon="flag"
                            text="Finish"
                          />
                          <BoxButton
                            category="ShipmentInfo"
                            action="Click Edit"
                            label={_id}
                            to={`/shipments/${
                              this.props.shipment._id && this.props.shipment._id
                            }#edit`}
                            icon="edit"
                            text="Edit"
                          />
                        </div>
                      ) : (
                        !this.props.isPublic && (
                          <div
                            className="shipment-card__field left address"
                            style={{
                              display: "flex",
                              color: "green",
                              justifyContent: "flex-end",
                              fontSize: "20px",
                            }}
                          >
                            Completed
                          </div>
                        )
                      )}
                      {tags.length ? (
                        <div className="shipment-card__tags">
                          <Tags noLink={true} tags={tags} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              this.state.isVisible && (
                <div
                  className="shipment-card__container"
                  style={{
                    // marginTop: isMobile ? "80px" : "",
                    // marginLeft: isMobile ? "-20px" : "",
                    maxWidth: isMobile ? "" : "",
                    margin: "0px",
                  }}
                  onMouseEnter={() =>
                    this.props.onHover &&
                    this.props.onHover(origin, destination)
                  }
                >
                  <Link
                    className="shlk-card shipment-card__box"
                    key={_id}
                    to={`/shipments/${_id}`}
                  >
                    <div
                      className="shipment-card__top"
                      style={{ padding: "15px" }}
                    >
                      <div className="shipment-card__info">
                        <div
                          className="shipment-card__field left name"
                          style={{ marginLeft: "0px", marginBottom: "10px" }}
                        >
                          {ShipmentsData.name
                        ? ShipmentsData.name.length > 30
                          ? `${ShipmentsData.name.slice(0, 30)}...`
                          : ShipmentsData.name.toUpperCase()
                        : ""}
                        </div>
                        <div
                          className="shipment-card__field right name"
                          style={{ marginBottom: "10px" }}
                        >
                          <span
                            className={` ${
                              this.props.showBoxButton &&
                              this.props.actions &&
                              "container-number"
                            }`}
                          >
                            {ShipmentsData.containerNumber
                          ? ShipmentsData.containerNumber.length < 10
                            ? ShipmentsData.containerNumber
                            : `${ShipmentsData.containerNumber.slice(0, 9)}...`
                          : "NA"}
                          </span>
                        </div>
                        <div
                          className="shipment-card__bar full_width"
                          style={{
                            marginBottom: "10px",
                            position: "relative",
                            height: "200px",
                            // width: "330px",
                          }}
                        >
                          {ShipmentsData &&
                          ShipmentsData.inspections &&
                          ShipmentsData.inspections[0] ? (
                            <>
                              {" "}
                              <div
                                onClick={this.prevSlide}
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  position: "absolute",
                                  top: "50%",
                                  left: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                  zIndex: 1,
                                  background: "white",
                                  color: "#19575c",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50px",
                                  padding: "px",
                                  flexDirection: "column",
                                }}
                              >
                                {"<"}
                              </div>
                              {currentSlide === 0 && (
                                <img
                                  src={fields && fields[0].images[0]}
                                  alt={`Inspection `}
                                  style={{
                                    height: "200px",
                                    width: "330px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                />
                              )}
                              {currentSlide === 1 && (
                                <img
                                  src={fields[1].images[0]}
                                  alt={`Inspection `}
                                  style={{
                                    height: "200px",
                                    width: "330px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                />
                              )}
                              {currentSlide === 2 && (
                                <img
                                  src={fields[2].images[0]}
                                  alt={`Inspection `}
                                  style={{
                                    height: "200px",
                                    width: "330px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                />
                              )}
                              {currentSlide === 3 && (
                                <img
                                  src={fields[3].images[0]}
                                  alt={`Inspection `}
                                  style={{
                                    height: "200px",
                                    width: "330px",
                                    objectFit: "cover",
                                    borderRadius: "10px",
                                  }}
                                />
                              )}
                              <div
                                onClick={this.nextSlide}
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  position: "absolute",
                                  top: "50%",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                  zIndex: 1,

                                  background: "white",
                                  color: "#19575c",
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  borderRadius: "50px",
                                  padding: "px",
                                  flexDirection: "column",
                                }}
                              >
                                {">"}
                              </div>
                            </>
                          ) : (
                            <span
                              className="shipment-card__bar full_width"
                              style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              No Inspections Yet
                            </span>
                          )}
                        </div>

                        <div
                          className="shipment-card__bar full_width"
                          style={{
                            flexDirection: "row",
                            marginBottom: "10px",
                            marginLeft:"0px",
                            marginRight:"0px"
                          }}
                        >
                          {!this.props.isPublic && (
                            <button
                              style={{
                                padding: "5px 12px",
                                borderRadius: "5px",
                                border: "2px solid #3d9aa1",
                                // marginBottom: "10px",
                                fontSize: "13px",
                                fontWeight: "600",
                                cursor: "pointer",
                                width: "100px",
                                display: "flex",
                                justifyContent: "center",
                                height: "32px",
                                alignItems: "center",
                              }}
                              className="tags-manager"
                              onClick={this.props.openTagTab}
                            >
                              {" "}
                              <ReactSVG
                                className="tags-manager__icon"
                                src="/assets/svg/pricetags-outline.svg"
                              />
                              <span style={{ fontSize: "16px" }}>Tags</span>
                            </button>
                          )}

                          <button
                            style={{
                              padding: "5px 12px",
                              borderRadius: "5px",
                              border: "2px solid #3d9aa1",
                              // marginBottom: "10px",
                              fontSize: "13px",
                              fontWeight: "600",
                              cursor: "pointer",
                              width: "100px",
                              display: "flex",
                              justifyContent: "center",
                              height: "32px",
                              alignItems: "center",
                            }}
                            className="tags-manager"
                            onClick={this.toggleExpanded}
                            // to="/tags"
                          >
                            <ReactSVG
                              className="info-icon"
                              src="/assets/svg/i_icon.svg"
                            />
                            <span style={{ fontSize: "16px" }}>Details</span>
                          </button>
                        </div>
                        <div
                          className="shipment-card__field left address"
                          style={{ margin: "0px" }}
                        >
                         {ShipmentsData.origin.label
                        ? ShipmentsData.origin.label.length > 20
                          ? `${ShipmentsData.origin.label.slice(0, 20)}...`
                          : ShipmentsData.origin.label
                        : ShipmentsData.origin.address.length > 20
                        ? `${ShipmentsData.origin.address.slice(0, 20)}...`
                        : ShipmentsData.origin.address}
                        </div>
                        <div className="shipment-card__field right address">
                        {ShipmentsData.destination.label
                        ? ShipmentsData.destination.label.length > 20
                          ? `${ShipmentsData.destination.label.slice(0, 20)}...`
                          : ShipmentsData.destination.label
                        : ShipmentsData.destination.address.length > 20
                        ? `${ShipmentsData.destination.address.slice(0, 20)}...`
                        : ShipmentsData.destination.address}
                        </div>

                        <div
                          className="shipment-card__bar full_width"
                          style={{
                            marginBottom: "25px",
                            marginRight: "5px",
                            marginLeft: "5px",
                          }}
                        >
                          <div className="shipment-bar">
                            {hubs.map((hub: any, index: any) => (
                              <React.Fragment key={hub._id}>
                                {/* Render point */}
                                <div
                                  className={`shipment-bar__point expanded ${
                                    hub.arrivalAct && "done"
                                  }`}
                                  style={{
                                    left: `${
                                      (index / (hubs.length - 1)) * 100
                                    }%`,
                                    zIndex: "2",
                                  }}
                                ></div>

                                {/* Render line if there is a next mode */}
                                {index < modes.length && (
                                  <div
                                    className={`shipment-bar__line expanded ${
                                      modes[index].status === "COMPLETED"
                                        ? "done"
                                        : ""
                                    } ${
                                      modes[index].status === "ONGOING"
                                        ? "ongoing"
                                        : ""
                                    }`}
                                    style={{
                                      position: "absolute",
                                      left: `${
                                        (index / (hubs.length - 1)) * 100
                                      }%`,
                                      top: "1px",
                                      width: `${
                                        (1 / (hubs.length - 1)) * 100
                                      }%`,
                                    }}
                                  >
                                    {/* Render progress for the ongoing segment */}
                                    {modes[index].status === "ONGOING" && (
                                      <div
                                        className="shipment-bar__progress"
                                        style={{
                                          position: "absolute",
                                          width: `${
                                            hubs[index] && hubs[index + 1]
                                              ? calculatePercentageDone(
                                                  hubs[index].address.location
                                                    .lat, // originLat
                                                  hubs[index].address.location
                                                    .lng, // originLng
                                                  hubs[index + 1].address
                                                    .location.lat, // destLat
                                                  hubs[index + 1].address
                                                    .location.lng, // destLng (should be lng)
                                                  currentLocation.lat, // actualLat
                                                  currentLocation.lng // actualLng
                                                )
                                              : "0"
                                          }%`,
                                          zIndex: "1",
                                          height: "3px",
                                          // top: "1px",
                                          backgroundColor: "#19575C",
                                        }}
                                      >
                                        {modes[index].modeType === "Land" &&
                                          isOngoingShipment && (
                                            <div className="shipment-bar__icon expanded">
                                              <ReactSVG
                                                src={`/assets/svg/truck.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Air" &&
                                          isOngoingShipment && (
                                            <div className="shipment-bar__icon expanded">
                                              <ReactSVG
                                                src={`/assets/svg/flight.svg`}
                                              />
                                            </div>
                                          )}
                                        {modes[index].modeType === "Sea" &&
                                          isOngoingShipment && (
                                            <div className="shipment-bar__icon expanded">
                                              <ReactSVG
                                                src={`/assets/svg/ship.svg`}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    )}
                                    {modes[index].status === "COMPLETED" &&
                                      index === modes.length - 1 && (
                                        <div
                                          className="shipment-bar__progress"
                                          style={{
                                            position: "absolute",
                                            width: `${
                                              hubs[index] && hubs[index + 1]
                                                ? calculatePercentageDone(
                                                    hubs[index].address.location
                                                      .lat, // originLat
                                                    hubs[index].address.location
                                                      .lng, // originLng
                                                    hubs[index + 1].address
                                                      .location.lat, // destLat
                                                    hubs[index + 1].address
                                                      .location.lng, // destLng (should be lng)
                                                    currentLocation.lat, // actualLat
                                                    currentLocation.lng // actualLng
                                                  )
                                                : "0"
                                            }%`,
                                            zIndex: "5",
                                            height: "3px",
                                            // top: "1px",
                                            backgroundColor: "#19575C",
                                          }}
                                        >
                                          {modes[index].modeType === "Land" && (
                                            <div className="shipment-bar__icon expanded"  style={{left:"98%"}}>
                                              <ReactSVG
                                                src={`/assets/svg/truck.svg`}
                                              />
                                            </div>
                                          )}
                                          {modes[index].modeType === "Air" && (
                                            <div className="shipment-bar__icon expanded"  style={{left:"98%"}}>
                                              <ReactSVG
                                                src={`/assets/svg/flight.svg`}
                                              />
                                            </div>
                                          )}
                                          {modes[index].modeType === "Sea" && (
                                            <div className="shipment-bar__icon expanded"  style={{left:"98%"}}>
                                              <ReactSVG
                                                src={`/assets/svg/ship.svg`}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    {!isOngoingShipment &&
                                      index === modes.length - 1 && (
                                        <div
                                          style={{
                                            zIndex: "3",
                                            position: "relative",
                                          }}
                                        >
                                          {modes[index].modeType === "Land" &&
                                            index === modes.length - 1 && (
                                              <div
                                                className="shipment-bar__icon expanded"
                                                style={{
                                                  position: "absolute",
                                                  left: "105%", // Move to the end of the bar
                                                  transform:
                                                    "translateX(-100%)", // Prevent overflow
                                                  backgroundColor: "#8b8b8b",
                                                }}
                                              >
                                                <ReactSVG
                                                  src={`/assets/svg/truck.svg`}
                                                />
                                              </div>
                                            )}
                                          {modes[index].modeType === "Air" &&
                                            index === modes.length - 1 && (
                                              <div
                                                className="shipment-bar__icon expanded"
                                                style={{
                                                  position: "absolute",
                                                  left: "105%", // Move to the end of the bar
                                                  transform:
                                                    "translateX(-100%)", // Prevent overflow
                                                  backgroundColor: "#8b8b8b",
                                                }}
                                              >
                                                <ReactSVG
                                                  src={`/assets/svg/flight.svg`}
                                                />
                                              </div>
                                            )}
                                          {modes[index].modeType === "Sea" &&
                                            index === modes.length - 1 && (
                                              <div
                                                className="shipment-bar__icon expanded"
                                                style={{
                                                  position: "absolute",
                                                  left: "105%", // Move to the end of the bar
                                                  transform:
                                                    "translateX(-100%)", // Prevent overflow
                                                  backgroundColor: "#8b8b8b",
                                                }}
                                              >
                                                <ReactSVG
                                                  src={`/assets/svg/ship.svg`}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      )}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                            <div
                              className="shipment-bar__point expanded"
                              style={{ left: "100%", zIndex: "2" }}
                            ></div>
                          </div>
                        </div>

                        <div
                          className="shipment-card__field left address"
                          style={{ margin: "0px", marginBottom: "10px" }}
                        >
                          {createdDate}
                        </div>
                        <div
                          className="shipment-card__field right address"
                          style={{ marginBottom: "10px" }}
                        >
                          ETA {estimatedDate}
                        </div>

                        <div
                          className={
                            "shipment-card__field left address event-icon-box"
                          }
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {this.showAlarmIcons()}
                        </div>

                        <div
                          className="shipment-card__bar full_width"
                          style={{
                            marginBottom: "5px",
                            width: "100%",
                            background: "transparent",
                            marginLeft: "0px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontWeight: "bold",
                          }}
                        >
                          <div
                            className="shipment-card__carbon"
                            style={{ width: "100px", marginLeft: "0px" }}
                          >
                            <span>CO2</span>
                            <span>
                            {ShipmentsData &&
                            (ShipmentsData.emissionsTillNow > 1000
                              ? `${(
                                  ShipmentsData.emissionsTillNow / 1000
                                ).toFixed(2)} t`
                              : `${Math.round(
                                  ShipmentsData.emissionsTillNow
                                )} kg`)}
                            </span>
                          </div>
                          <div
                            className="shipment-card__carbon"
                            style={{ width: "100px", marginLeft: "10px" }}
                          >
                            <span>Temp</span>
                            <span>{temperature}&deg;C</span>
                          </div>
                          <div
                            className="shipment-card__carbon"
                            style={{
                              width: "100px",
                              marginLeft: "10px",
                              marginRight: "0px",
                            }}
                          >
                            <span>Battery</span>
                            <span>{battery} %</span>
                          </div>
                        </div>
                        {this.props.showBoxButton &&
                        isOngoingShipment &&
                        !this.props.isPublic &&
                        this.props.actions ? (
                          <div
                            className="shipment-card__bar full_width info-btn-wrapper bc-wrapper"
                            style={{
                              flexDirection: "row",
                              marginBottom: "15px",
                            }}
                          >
                            <BoxButton
                              category="ShipmentInfo"
                              action="Click Cancel"
                              label={_id}
                              onClick={this.delete}
                              icon="close"
                              text="Cancel"
                            />
                            <BoxButton
                              category="ShipmentInfo"
                              action="Click Finish"
                              label={_id}
                              onClick={this.complete}
                              icon="flag"
                              text="Finish"
                            />
                            <BoxButton
                              category="ShipmentInfo"
                              action="Click Edit"
                              label={_id}
                              to={`/shipments/${this.props.shipment._id}#edit`}
                              icon="edit"
                              text="Edit"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {!isOngoingShipment && (
                          <div
                            className="shipment-card__field left address"
                            style={{
                              flexDirection: "row",
                              color: "green",
                            }}
                          >
                            Completed
                          </div>
                        )}

                        <div
                          className="shipment-card__bar full_width"
                          style={{ marginBottom: "0px" }}
                        >
                          Last updated {formattedDate}
                        </div>
                        {tags.length ? (
                          <div className="shipment-card__tags">
                            <Tags noLink={true} tags={tags} />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            )}
          </>
        )}
      </>
    );
  }
}
export default withRouter(ShipmentCardV1);
