// // // import React, { useState, useEffect } from "react";
// // // import { SeaMode } from "./index";
// // // import Input from "../Input";
// // // import Dropdown from "../Dropdown";
// // // import { getPortList, getPortNearest } from "services/Trucks";
// // // import Shipment from "components/Shipment";

// // // interface SeaModeProps {
// // //   seaModeData: SeaMode;
// // //   onChange: (field: string, value: any) => void;
// // //   update: any; // Accept the update prop
// // // }

// // // const SeaModeComponent: React.FC<SeaModeProps> = ({
// // //   seaModeData,
// // //   onChange,
// // //   update,
// // // }) => {
// // //   const [portOptions, setPortOptions] = useState<string[]>([]);
// // //   const [nearstOriginOptions, setNearestOriginOptions] = useState<string[]>([]);
// // //   const [nearstDestinationOptions, setNearestDestinationOptions] = useState<
// // //     string[]
// // //   >([]);
// // //   const [error, setError] = useState<string | null>(null);
// // //   const [lastAddressElement, setLastAddressElement] = useState<string>("");

// // //   useEffect(() => {
// // //     const addressParts = update.origin.address
// // //       ? update.origin.address.split(",")
// // //       : ["United Kingdom"];
// // //     const lastElement = addressParts[addressParts.length - 1].trim();
// // //     setLastAddressElement(lastElement);

// // //     const fetchNearestOriginOptions = async () => {
// // //       try {
// // //         const response = await getPortNearest({
// // //           longitude: update.origin.location.lng,
// // //           latitude: update.origin.location.lat,
// // //         });
// // //         // Extract the "Main Port Name" from each object in the response array
// // //         const nearestNames = response.nearestPorts.map(
// // //           (port: any) => port["Main Port Name"]
// // //         );
// // //         setNearestOriginOptions(nearestNames);
// // //       } catch (error) {
// // //         console.error("Error fetching port list:", error);
// // //         setError("Error fetching port list.");
// // //       }
// // //     };
// // //     const fetchNearestDestinationOptions = async () => {
// // //       try {
// // //         const response = await getPortNearest({
// // //           longitude: update.destination.location.lng,
// // //           latitude: update.destination.location.lat,
// // //         });
// // //         // Extract the "Main Port Name" from each object in the response array
// // //         const nearestNames = response.nearestPorts.map(
// // //           (port: any) => port["Main Port Name"]
// // //         );
// // //         setNearestDestinationOptions(nearestNames);
// // //       } catch (error) {
// // //         console.error("Error fetching port list:", error);
// // //         setError("Error fetching port list.");
// // //       }
// // //     };

// // //     fetchNearestOriginOptions();
// // //     fetchNearestDestinationOptions();
// // //   }, []);

// // //   return (
// // //     <div>
// // //       {error && <div className="error">{error}</div>}
// // //       <div className="new-box-half">
// // //         <Input
// // //           shipment={seaModeData}
// // //           onChange={onChange}
// // //           field="imo"
// // //           label="IMO/MMSI"
// // //         />
// // //       </div>
// // //       <div className="new-box-half">
// // //         <Dropdown
// // //           parentObject={seaModeData}
// // //           onChange={onChange}
// // //           field="shipType"
// // //           label="Ship Type"
// // //           options={["Ocean", "Feeder", "Both"]}
// // //         />
// // //       </div>

// // //       <div className="new-box-half">
// // //         <Dropdown
// // //           parentObject={seaModeData}
// // //           onChange={onChange}
// // //           field="originPort"
// // //           label="Nearest Origin Port"
// // //           options={nearstOriginOptions ? nearstOriginOptions : []}
// // //         />
// // //       </div>
// // //       <div className="new-box-half">
// // //         <Dropdown
// // //           parentObject={seaModeData}
// // //           onChange={onChange}
// // //           field="destinationPort"
// // //           label="Nearest Destination Port"
// // //           options={nearstDestinationOptions ? nearstDestinationOptions : []}
// // //         />
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default SeaModeComponent;

// // // import React, { useState, useEffect } from "react";
// // // import { SeaMode } from "./index";
// // // import Input from "../Input";
// // // import Dropdown from "../Dropdown";
// // // import { getPortList, getPortNearest } from "services/Trucks";
// // // import Shipment from "components/Shipment";

// // // interface SeaModeProps {
// // //   seaModeData: SeaMode;
// // //   onChange: (field: string, value: any) => void;
// // //   update: any; // Accept the update prop
// // // }

// // // const SeaModeComponent: React.FC<SeaModeProps> = ({
// // //   seaModeData,
// // //   onChange,
// // //   update,
// // // }) => {
// // //   const [portOptions, setPortOptions] = useState<string[]>([]);
// // //   const [nearstOriginOptions, setNearestOriginOptions] = useState<any[]>([]); // Changed to `any[]` to hold full port data
// // //   const [nearstDestinationOptions, setNearestDestinationOptions] = useState<any[]>([]); // Changed to `any[]` to hold full port data
// // //   const [error, setError] = useState<string | null>(null);
// // //   const [lastAddressElement, setLastAddressElement] = useState<string>("");

// // //   useEffect(() => {
// // //     const addressParts = update.origin.address
// // //       ? update.origin.address.split(",")
// // //       : ["United Kingdom"];
// // //     const lastElement = addressParts[addressParts.length - 1].trim();
// // //     setLastAddressElement(lastElement);

// // //     const fetchNearestOriginOptions = async () => {
// // //       try {
// // //         const response = await getPortNearest({
// // //           longitude: update.origin.location.lng,
// // //           latitude: update.origin.location.lat,
// // //         });
// // //         // Save full port data, including Main Port Name and coordinates
// // //         setNearestOriginOptions(response.nearestPorts);
// // //       } catch (error) {
// // //         console.error("Error fetching port list:", error);
// // //         setError("Error fetching port list.");
// // //       }
// // //     };

// // //     const fetchNearestDestinationOptions = async () => {
// // //       try {
// // //         const response = await getPortNearest({
// // //           longitude: update.destination.location.lng,
// // //           latitude: update.destination.location.lat,
// // //         });
// // //         // Save full port data, including Main Port Name and coordinates
// // //         setNearestDestinationOptions(response.nearestPorts);
// // //       } catch (error) {
// // //         console.error("Error fetching port list:", error);
// // //         setError("Error fetching port list.");
// // //       }
// // //     };

// // //     fetchNearestOriginOptions();
// // //     fetchNearestDestinationOptions();
// // //   }, []);

// // // // Helper function to handle port selection and update the coordinates
// // // const handlePortSelection = (field: string, selectedPort: any) => {
// // //   if (!selectedPort || !selectedPort.coordinates) {
// // //     console.error("Selected port data is missing or malformed", selectedPort);
// // //     return;
// // //   }

// // //   const selectedPortData = {
// // //     address: selectedPort["Main Port Name"], // Main Port Name
// // //     location: {
// // //       lat: selectedPort.coordinates.coordinates[1], // Lat is the second element in the array
// // //       lng: selectedPort.coordinates.coordinates[0], // Lng is the first element in the array
// // //     },
// // //   };

// // //   // Log to verify the selectedPortData structure
// // //   console.log("Updating field:", field, "with data:", selectedPortData);

// // //   // Update the field (originPort or destinationPort)
// // //   onChange(field, selectedPortData);
// // // };

// // //   // Map nearest options to just Main Port Name for the dropdown display
// // //   const mapPortOptions = (ports: any[]) => {
// // //     return ports.map((port) => port["Main Port Name"]);
// // //   };

// // //   return (
// // //     <div>
// // //       {error && <div className="error">{error}</div>}
// // //       <div className="new-box-half">
// // //         <Input
// // //           shipment={seaModeData}
// // //           onChange={onChange}
// // //           field="imo"
// // //           label="IMO/MMSI"
// // //         />
// // //       </div>
// // //       <div className="new-box-half">
// // //         <Dropdown
// // //           parentObject={seaModeData}
// // //           onChange={onChange}
// // //           field="shipType"
// // //           label="Ship Type"
// // //           options={["Ocean", "Feeder", "Both"]}
// // //         />
// // //       </div>

// // //       <div className="new-box-half">
// // //         <Dropdown
// // //           parentObject={seaModeData}
// // //           onChange={(field, selectedValue) => {
// // //             const selectedPort = nearstOriginOptions.find(
// // //               (port) => port["Main Port Name"] === selectedValue
// // //             );
// // //             if (selectedPort) {
// // //               handlePortSelection("originPort", selectedPort); // Update originPort with selected port data
// // //             }
// // //           }}
// // //           field="originPort"
// // //           label="Nearest Origin Port"
// // //           options={mapPortOptions(nearstOriginOptions)} // Map only the Main Port Names for the dropdown
// // //         />
// // //       </div>

// // //       <div className="new-box-half">
// // //         <Dropdown
// // //           parentObject={seaModeData}
// // //           onChange={(field, selectedValue) => {
// // //             const selectedPort = nearstDestinationOptions.find(
// // //               (port) => port["Main Port Name"] === selectedValue
// // //             );
// // //             if (selectedPort) {
// // //               handlePortSelection("destinationPort", selectedPort); // Update destinationPort with selected port data
// // //             }
// // //           }}
// // //           field="destinationPort"
// // //           label="Nearest Destination Port"
// // //           options={mapPortOptions(nearstDestinationOptions)} // Map only the Main Port Names for the dropdown
// // //         />
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default SeaModeComponent;
// // import React, { useState, useEffect } from "react";
// // import { SeaMode } from "./index";
// // import Input from "../Input";
// // import Dropdown from "../Dropdown";
// // import { getPortList, getPortNearest } from "services/Trucks";
// // import Shipment from "components/Shipment";

// // interface SeaModeProps {
// //   seaModeData: SeaMode;
// //   onChange: (field: string, value: any) => void;
// //   update: any; // Accept the update prop
// // }

// // const SeaModeComponent: React.FC<SeaModeProps> = ({
// //   seaModeData,
// //   onChange,
// //   update,
// // }) => {
// //   const [nearestOriginOptions, setNearestOriginOptions] = useState<any[]>([]);
// //   const [nearestDestinationOptions, setNearestDestinationOptions] = useState<any[]>([]);
// //   const [error, setError] = useState<string | null>(null);

// //   useEffect(() => {
// //     const fetchNearestOriginOptions = async () => {
// //       try {
// //         const response = await getPortNearest({
// //           longitude: update.origin.location.lng,
// //           latitude: update.origin.location.lat,
// //         });
// //         setNearestOriginOptions(response.nearestPorts);
// //       } catch (error) {
// //         console.error("Error fetching origin port list:", error);
// //         setError("Error fetching origin port list.");
// //       }
// //     };

// //     const fetchNearestDestinationOptions = async () => {
// //       try {
// //         const response = await getPortNearest({
// //           longitude: update.destination.location.lng,
// //           latitude: update.destination.location.lat,
// //         });
// //         setNearestDestinationOptions(response.nearestPorts);
// //       } catch (error) {
// //         console.error("Error fetching destination port list:", error);
// //         setError("Error fetching destination port list.");
// //       }
// //     };

// //     fetchNearestOriginOptions();
// //     fetchNearestDestinationOptions();
// //   }, [update]);

// //   // Helper function to handle port selection and update the coordinates
// //   const handlePortSelection = (field: string, selectedPort: any) => {
// //     if (!selectedPort || !selectedPort.coordinates) {
// //       console.error("Selected port data is missing or malformed", selectedPort);
// //       return;
// //     }

// //     const selectedPortData = {
// //       address: selectedPort["Main Port Name"], // Main Port Name
// //       location: {
// //         lat: selectedPort.coordinates.coordinates[1], // Lat is the second element in the array
// //         lng: selectedPort.coordinates.coordinates[0], // Lng is the first element in the array
// //       },
// //     };

// //     // Update the field (originPort or destinationPort)
// //     onChange(field, selectedPortData);
// //   };

// //   // Map nearest options to just Main Port Name for the dropdown display
// //   const mapPortOptions = (ports: any[]) => {
// //     return ports.map((port) => port["Main Port Name"]);
// //   };

// //   return (
// //     <div>
// //       {error && <div className="error">{error}</div>}
// //       <div className="new-box-half">
// //         <Input
// //           shipment={seaModeData}
// //           onChange={onChange}
// //           field="imo"
// //           label="IMO/MMSI"
// //         />
// //       </div>
// //       <div className="new-box-half">
// //         <Dropdown
// //           parentObject={seaModeData}
// //           onChange={onChange}
// //           field="shipType"
// //           label="Ship Type"
// //           options={["Ocean", "Feeder", "Both"]}
// //         />
// //       </div>

// //       {/* Origin Port Dropdown */}
// //       <div className="new-box-half">
// //         <Dropdown
// //           parentObject={seaModeData}
// //           onChange={(field, selectedValue) => {
// //             const selectedPort = nearestOriginOptions.find(
// //               (port) => port["Main Port Name"] === selectedValue
// //             );
// //             if (selectedPort) {
// //               handlePortSelection("originPort", selectedPort); // Update originPort with selected port data
// //             }
// //           }}
// //           field="originPort"
// //           label="Nearest Origin Port"
// //           options={["Select a port", ...mapPortOptions(nearestOriginOptions)]} // Add "Select a port" as default
// //         />
// //       </div>

// //       {/* Destination Port Dropdown */}
// //       <div className="new-box-half">
// //         <Dropdown
// //           parentObject={seaModeData}
// //           onChange={(field, selectedValue) => {
// //             const selectedPort = nearestDestinationOptions.find(
// //               (port) => port["Main Port Name"] === selectedValue
// //             );
// //             if (selectedPort) {
// //               handlePortSelection("destinationPort", selectedPort); // Update destinationPort with selected port data
// //             }
// //           }}
// //           field="destinationPort"
// //           label="Nearest Destination Port"
// //           options={["Select a port", ...mapPortOptions(nearestDestinationOptions)]} // Add "Select a port" as default
// //         />
// //       </div>
// //     </div>
// //   );
// // };

// // export default SeaModeComponent;

// import React, { useState, useEffect } from "react";
// import Input from "../Input";
// import { getPortNearest } from "services/Trucks";
// import { SeaMode } from "./index";

// interface SeaModeProps {
//   seaModeData: SeaMode;
//   onChange: (field: string, value: any) => void;
//   update: any; // Accept the update prop
// }

// const SeaModeComponent: React.FC<SeaModeProps> = ({
//   seaModeData,
//   onChange,
//   update,
// }) => {
//   const [nearestOriginOptions, setNearestOriginOptions] = useState<any[]>([]);
//   const [nearestDestinationOptions, setNearestDestinationOptions] = useState<any[]>([]);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const fetchNearestOriginOptions = async () => {
//       try {
//         const response = await getPortNearest({
//           longitude: update.origin.location.lng,
//           latitude: update.origin.location.lat,
//         });
//         setNearestOriginOptions(response.nearestPorts);
//       } catch (error) {
//         console.error("Error fetching origin port list:", error);
//         setError("Error fetching origin port list.");
//       }
//     };

//     const fetchNearestDestinationOptions = async () => {
//       try {
//         const response = await getPortNearest({
//           longitude: update.destination.location.lng,
//           latitude: update.destination.location.lat,
//         });
//         setNearestDestinationOptions(response.nearestPorts);
//       } catch (error) {
//         console.error("Error fetching destination port list:", error);
//         setError("Error fetching destination port list.");
//       }
//     };

//     fetchNearestOriginOptions();
//     fetchNearestDestinationOptions();
//   }, [update]);

//   const handlePortSelection = (field: string, selectedPortName: string) => {
//     const selectedPort = nearestOriginOptions.find(
//       (port) => port["Main Port Name"] === selectedPortName
//     );

//     if (!selectedPort || !selectedPort.coordinates) {
//       console.error("Selected port data is missing or malformed", selectedPort);
//       return;
//     }

//     const selectedPortData = {
//       address: selectedPort["Main Port Name"],
//       location: {
//         lat: selectedPort.coordinates.coordinates[1], // Latitude
//         lng: selectedPort.coordinates.coordinates[0], // Longitude
//       },
//     };

//     onChange(field, selectedPortData);
//   };

//   const mapPortOptions = (ports: any[]) => {
//     return ports.map((port) => port["Main Port Name"]);
//   };

//   return (
//     <div>
//       {error && <div className="error">{error}</div>}

//       {/* IMO/MMSI Field */}
//       <div className="new-box-half">
//         <Input
//           shipment={seaModeData}
//           onChange={onChange}
//           field="imo"
//           label="IMO/MMSI"
//         />
//       </div>

//       {/* Ship Type Select */}
//       <div className="new-box-half">
//         <label htmlFor="shipType">Ship Type</label>
//         <select
//           id="shipType"
//           value={seaModeData.shipType || ""}
//           onChange={(e) => onChange("shipType", e.target.value)}
//         >
//           <option value="Ocean">Ocean</option>
//           <option value="Feeder">Feeder</option>
//           <option value="Both">Both</option>
//         </select>
//       </div>

//       {/* Origin Port Select */}
//       <div className="new-box-half">
//         <label htmlFor="originPort">Nearest Origin Port</label>
//         <select
//           id="originPort"
//           value={seaModeData.originPort&& seaModeData.originPort.address || ""}
//           onChange={(e) => handlePortSelection("originPort", e.target.value)}
//         >
//           <option value="">Select a port</option>
//           {mapPortOptions(nearestOriginOptions).map((portName) => (
//             <option key={portName} value={portName}>
//               {portName}
//             </option>
//           ))}
//         </select>
//       </div>

//       {/* Destination Port Select */}
//       <div className="new-box-half">
//         <label htmlFor="destinationPort">Nearest Destination Port</label>
//         <select
//           id="destinationPort"
//           value={seaModeData.destinationPort && seaModeData.destinationPort.address || ""}
//           onChange={(e) => handlePortSelection("destinationPort", e.target.value)}
//         >
//           <option value="">Select a port</option>
//           {mapPortOptions(nearestDestinationOptions).map((portName) => (
//             <option key={portName} value={portName}>
//               {portName}
//             </option>
//           ))}
//         </select>
//       </div>
//     </div>
//   );
// };

// export default SeaModeComponent;

import React, { useState, useEffect } from "react";
import Input from "../Input";
import { getPortNearest } from "services/Trucks";
import { SeaMode } from "./index";

interface SeaModeProps {
  seaModeData: SeaMode;
  onChange: (field: string, value: any) => void;
  update: any; // Accept the update prop
}

const SeaModeComponent: React.FC<SeaModeProps> = ({
  seaModeData,
  onChange,
  update,
}) => {
  const [nearestOriginOptions, setNearestOriginOptions] = useState<any[]>([]);
  const [nearestDestinationOptions, setNearestDestinationOptions] = useState<
    any[]
  >([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNearestOriginOptions = async () => {
      try {
        const response = await getPortNearest({
          longitude: update.origin.location.lng,
          latitude: update.origin.location.lat,
        });
        setNearestOriginOptions(response.nearestPorts);
      } catch (error) {
        console.error("Error fetching origin port list:", error);
        setError("Error fetching origin port list.");
      }
    };

    const fetchNearestDestinationOptions = async () => {
      try {
        const response = await getPortNearest({
          longitude: update.destination.location.lng,
          latitude: update.destination.location.lat,
        });
        setNearestDestinationOptions(response.nearestPorts);
      } catch (error) {
        console.error("Error fetching destination port list:", error);
        setError("Error fetching destination port list.");
      }
    };

    fetchNearestOriginOptions();
    fetchNearestDestinationOptions();
  }, [update]);

  const handlePortSelection = (field: string, selectedPortName: string) => {
    // Determine which port options to use based on the field (origin or destination)
    const selectedPort =
      field === "originPort"
        ? nearestOriginOptions.find(
            (port) => port["Main Port Name"] === selectedPortName
          )
        : nearestDestinationOptions.find(
            (port) => port["Main Port Name"] === selectedPortName
          );

    if (!selectedPort || !selectedPort.coordinates) {
      console.error("Selected port data is missing or malformed", selectedPort);
      return;
    }

    const selectedPortData = {
      address: selectedPort["Main Port Name"],
      location: {
        lat: selectedPort.coordinates.coordinates[1], // Latitude
        lng: selectedPort.coordinates.coordinates[0], // Longitude
      },
    };

    onChange(field, selectedPortData);
  };

  const mapPortOptions = (ports: any[]) => {
    return ports.map((port) => port["Main Port Name"]);
  };

  return (
    <div>
      {error && <div className="error">{error}</div>}

      {/* IMO/MMSI Field */}
      <div className="new-box-half">
        <Input
          shipment={seaModeData}
          onChange={onChange}
          field="imo"
          label="IMO/MMSI"
          
        />
      </div>

      {/* Ship Type Select */}
      <div className="new-box-half">
        <label
          htmlFor="shipType"
          style={{
            display: "block",
            fontSize: "12px",
            marginBottom: "5px",
            textTransform: "capitalize",
          }}
        >
          Ship Type
        </label>
        <select
          id="shipType"
          value={seaModeData.shipType || ""}
          onChange={(e) => onChange("shipType", e.target.value)}
          style={{
            backgroundColor: "#ced7e7",
            borderRadius: "9px",
            border: "none",
            color: "#19575c",
            height: "30px",
            padding: "5px 10px",
            fontSize: "12px",
            width: "100%",
          }}
        >
          <option value="Ocean">Ocean</option>
          <option value="Feeder">Feeder</option>
          <option value="Both">Both</option>
        </select>
      </div>

      {/* Origin Port Select */}
      <div className="new-box-half">
        <label
          htmlFor="originPort"
          style={{
            display: "block",
            fontSize: "12px",
            marginBottom: "5px",
            textTransform: "capitalize",
          }}
        >
          Nearest Origin Port
        </label>
        <select
          id="originPort"
          value={
            (seaModeData.originPort && seaModeData.originPort.address) || ""
          }
          onChange={(e) => handlePortSelection("originPort", e.target.value)}
          style={{
            backgroundColor: "#ced7e7",
            borderRadius: "9px",
            border: "none",
            color: "#19575c",
            height: "30px",
            padding: "5px 10px",
            fontSize: "12px",
            width: "100%",
          }}
        >
          <option value="">Select a port</option>
          {mapPortOptions(nearestOriginOptions).map((portName) => (
            <option key={portName} value={portName}>
              {portName}
            </option>
          ))}
        </select>
      </div>

      {/* Destination Port Select */}
      <div className="new-box-half">
        <label
          htmlFor="destinationPort"
          style={{
            display: "block",
            fontSize: "12px",
            marginBottom: "5px",
            textTransform: "capitalize",
          }}
        >
          Nearest Destination Port
        </label>
        <select
          id="destinationPort"
          value={
            (seaModeData.destinationPort &&
              seaModeData.destinationPort.address) ||
            ""
          }
          onChange={(e) =>
            handlePortSelection("destinationPort", e.target.value)
          }
          style={{
            backgroundColor: "#ced7e7",
            borderRadius: "9px",
            border: "none",
            color: "#19575c",
            height: "30px",
            padding: "5px 10px",
            fontSize: "12px",
            width: "100%",
          }}
        >
          <option value="">Select a port</option>
          {mapPortOptions(nearestDestinationOptions).map((portName) => (
            <option key={portName} value={portName}>
              {portName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SeaModeComponent;
