import React, { Component } from "react";
import Input from "components/Inputs/Input/Settings";
import Selectable from "components/Inputs/Selectable";
import Dropdown from "components/Inputs/Dropdown";
import { getFieldUnit, getSettingValue } from "./../utils";
import "./styles.scss";

interface Props {
  update: any;
  data: any;
  path: string;
  name: string;
  info?: string;
  disabled?: boolean;
}

export default class Settings extends Component<Props> {
  renderInput = (field: string, value: any, disabled: boolean) => {
    const error =
      this.props.data.validation.errors[`${this.props.name}_${field}`];
    const unit = getFieldUnit(`${this.props.path}.${field}`);
    const updateHandler = (value: any) =>
      this.props.update(this.props.path, field, value);

    return (
      <Input
        key={`Field_${field}`}
        update={updateHandler}
        error={error}
        unit={unit}
        name={field}
        value={value}
        disabled={disabled}
      />
    );
  };

  renderFields = (setting: any, disabled: boolean) => {
    const children = Object.assign({}, setting);
    delete children.enabled;

    return Object.entries(children).map(([field, value]: [string, any]) =>
      this.renderInput(field, value, disabled)
    );
  };

  getCheckboxProps = (setting: any, disabled: boolean) => ({
    name:
      this.props.name.charAt(0).toLocaleUpperCase() +
      this.props.name.slice(1).toLocaleLowerCase(),
    checked: setting.enabled,
    info: this.props.info,
    disabled,
    onClick:
      "enabled" in setting
        ? (value: any) => this.props.update(this.props.path, "enabled", value)
        : null,
  });

  render() {
    const setting = getSettingValue(this.props.path, this.props.data.settings);

    if (!setting) return <></>;
    const disabled = this.props.data.readOnly || this.props.disabled;

    const checkbox = (
      <Selectable {...this.getCheckboxProps(setting, disabled)} />
    );
    const fields = this.renderFields(setting, disabled);

    return (
      <div
        className={`field-container ${this.props.disabled ? "disabled" : ""}`}
      >
        {!fields.length ? (
          checkbox
        ) : (
          <Dropdown title={checkbox}>
            <div className="settings-group">{fields}</div>
          </Dropdown>
        )}
      </div>
    );
  }
}
