// import React, { Component } from 'react';
// import ContentLoader from 'components/Loading/ContentLoader';
// import { getDefault } from 'services/settings';
// import { updateSettingValue } from './utils';
// import Field from './Field';
// import './styles.scss';

// interface Props {
//     update: any;
//     data: any;
//     className?: string;
// }

// interface State {
//     loading: boolean;
// }

// export default class NewFormSettings extends Component<Props, State> {
//     static defaultProps = { className: '' }

//     constructor(props: Props) {
//         super(props);

//         this.state = { loading: true };
//     }

//     componentDidMount() {
//         if(this.props.data.settings) {
//             return this.setState({ loading : false });
//         }

//         getDefault()
//             .then((settings: any) => {
//                 this.props.update(settings);
//                 this.setState({ loading: false });
//             });
//     }

//     update = (path: string, setting: string, value: any) => {
//         const updatedSettings = this.props.data.settings;

//         updateSettingValue(path, setting, value, updatedSettings);

//         this.props.update(updatedSettings);
//     }

//     renderForm = () => {
//         const hasCollisionSetting = this.props.data.settings.tracking.collision;
//         return <div className={"form-settings " + this.props.className}>
//             {this.props.data.settings.tracking && (
//                 <div>
//                     <div className="settings-title">Tracking</div>

//                     <Field
//                         data={this.props.data}
//                         update={this.update}
//                         path="tracking.params" name="mode"
//                         info="Changes in tracking frequency will affect battery lifespan" />
//                 </div>
//             )}
//             <div>
//                 <div className="settings-title">Notifications</div>

//                 <Field update={this.update} data={this.props.data}
//                     path="notifications.emails" name="emails"
//                     info="Receive an email for every alarm triggered" />
//             </div>
//             <div>
//                 <div className="settings-title">Triggers</div>

//                 <Field update={this.update} data={this.props.data}
//                     path="triggers.temperature" name="temperature"
//                     info="An alarm will trigger when the temperature of drops lower than MIN (ºC) or higher than MAX (ºC)" />

//                 <Field update={this.update} data={this.props.data}
//                     path="triggers.idle" name="idle"
//                     info="An alarm will trigger when the eShell hasn't move more than DISTANCE (meters) in the last TIME (minutes)" />

//                 <Field update={this.update} data={this.props.data}
//                     path="triggers.battery" name="battery"
//                     info="An alarm will trigger when the battery drops more than PERCENTAGE (%)" />

//                 <Field update={this.update} data={this.props.data}
//                     path="triggers.arrival" name="arrival"
//                     info="An alarm will trigger when the eShell gets closer than METERS from the destination" />

//                 <Field update={this.update} data={this.props.data}
//                     path="triggers.light" name="light"
//                     info="An alarm will trigger when the light sensor has been trigger after being in the dark" />

//                 {hasCollisionSetting && <Field update={this.update} data={this.props.data}
//                     path="triggers.collision" name="collision"
//                     info="An alarm will trigger when the acceleration sensor gets above the threshold" />
//                 }

//                 <Field update={this.update} data={this.props.data}
//                     path="triggers.vessel" name="vessel"
//                     info="Enable alarms for vessel departure and arrival" />
//             </div>
//         </div>;
//     }

//     render() {
//         return (
//             <ContentLoader loading={this.state.loading} content={() =>
//                 this.renderForm()
//             } />
//         );
//     }
// }
import React, { Component } from "react";
import ContentLoader from "components/Loading/ContentLoader";
import { getDefault } from "services/settings";
import { updateSettingValue } from "./utils";
import Field from "./Field";
import { ReactSVG } from "react-svg"; // Import the ReactSVG component
import "./styles.scss";

interface Props {
  update: any;
  data: any;
  className?: string;
}

interface State {
  loading: boolean;
  openTooltip: string | null; // To manage the opened tooltip
}

export default class NewFormSettings extends Component<Props, State> {
  static defaultProps = { className: "" };

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      openTooltip: null, // Initialize state for tooltips
    };
  }

  componentDidMount() {
    if (this.props.data.settings) {
      return this.setState({ loading: false });
    }

    getDefault().then((settings: any) => {
      this.props.update(settings);
      this.setState({ loading: false });
    });
  }

  update = (path: string, setting: string, value: any) => {
    const updatedSettings = this.props.data.settings;

    updateSettingValue(path, setting, value, updatedSettings);

    this.props.update(updatedSettings);
  };

  toggleTooltip = (name: string) => {
    this.setState((prevState) => ({
      openTooltip: prevState.openTooltip === name ? null : name,
    }));
  };

  renderTooltip = (name: string, text: string) =>
    this.state.openTooltip === name && (
      <div
        className="tooltip-content"
        style={{ marginBottom: "5px", marginTop: "-15px" }}
      >
        {text}
      </div>
    );

  renderForm = () => {
    const hasCollisionSetting = this.props.data.settings.tracking.collision;
console.log(this.props.data,"data");
    return (
      <div className={"form-settings " + this.props.className}>
        {this.props.data.settings.tracking && (
          <div>
            <div className="settings-title">Tracking</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Field
                data={this.props.data}
                update={this.update}
                path="tracking.params"
                name="mode"
              />
              <ReactSVG
                style={{
                  color: "black",
                  cursor: "pointer",
                  marginLeft: "3px",
                  marginTop: "2px",
                }}
                src={"/assets/svg/info.svg"}
                onClick={() => this.toggleTooltip("mode")}
              />
            </div>
            {this.renderTooltip(
              "mode",
              "Changes in tracking frequency will affect battery lifespan"
            )}
          </div>
        )}

        <div>
          <div className="settings-title">Notifications</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={this.update}
              data={this.props.data}
              path="notifications.emails"
              name="emails"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => this.toggleTooltip("emails")}
            />
          </div>
          {this.renderTooltip(
            "emails",
            "Receive an email for every alarm triggered"
          )}
        </div>

        <div>
          <div className="settings-title">Triggers</div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={this.update}
              data={this.props.data}
              path="triggers.temperature"
              name="temperature"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => this.toggleTooltip("temperature")}
            />
          </div>
          {this.renderTooltip(
            "temperature",
            "An alarm will trigger when the temperature drops lower than MIN or higher than MAX (ºC)"
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={this.update}
              data={this.props.data}
              path="triggers.idle"
              name="idle"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "3px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => this.toggleTooltip("idle")}
            />
          </div>
          {this.renderTooltip(
            "idle",
            "An alarm will trigger when the device hasn’t moved more than a certain distance in a set amount of time"
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={this.update}
              data={this.props.data}
              path="triggers.battery"
              name="battery"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginTop: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => this.toggleTooltip("battery")}
            />
          </div>
          {this.renderTooltip(
            "battery",
            "An alarm will trigger when the battery drops below a certain percentage"
          )}
          {this.props.data.settings &&
            this.props.data.settings.triggers &&
            this.props.data.settings.triggers.arrival && (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Field
                    update={this.update}
                    data={this.props.data}
                    path="triggers.arrival"
                    name="arrival"
                  />
                  <ReactSVG
                    style={{
                      color: "black",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginTop: "5px",
                    }}
                    src={"/assets/svg/info.svg"}
                    onClick={() => this.toggleTooltip("arrival")}
                  />
                </div>
                {this.renderTooltip(
                  "arrival",
                  "An alarm will trigger when the device gets closer than a certain distance to its destination"
                )}
              </>
            )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Field
              update={this.update}
              data={this.props.data}
              path="triggers.light"
              name="light"
            />
            <ReactSVG
              style={{
                color: "black",
                cursor: "pointer",
                marginLeft: "3px",
                marginBottom: "5px",
              }}
              src={"/assets/svg/info.svg"}
              onClick={() => this.toggleTooltip("light")}
            />
          </div>
          {this.renderTooltip(
            "light",
            "An alarm will trigger when the light sensor detects a change from dark to light"
          )}

          {hasCollisionSetting && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Field
                  update={this.update}
                  data={this.props.data}
                  path="triggers.collision"
                  name="collision"
                />
                <ReactSVG
                  style={{
                    color: "black",
                    cursor: "pointer",
                    marginLeft: "3px",
                    marginBottom: "5px",
                  }}
                  src={"/assets/svg/info.svg"}
                  onClick={() => this.toggleTooltip("collision")}
                />
              </div>
              {this.renderTooltip(
                "collision",
                "An alarm will trigger when the acceleration sensor detects movement above a certain threshold"
              )}
            </>
          )}
          {this.props.data.settings &&
            this.props.data.settings.triggers &&
            this.props.data.settings.triggers.vessel && (
              <>
                {" "}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Field
                    update={this.update}
                    data={this.props.data}
                    path="triggers.vessel"
                    name="vessel"
                  />
                  <ReactSVG
                    style={{
                      color: "black",
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginBottom: "5px",
                    }}
                    src={"/assets/svg/info.svg"}
                    onClick={() => this.toggleTooltip("vessel")}
                  />
                </div>
                {this.renderTooltip(
                  "vessel",
                  "Enable alarms for vessel departure and arrival"
                )}
              </>
            )}
        </div>
      </div>
    );
  };

  render() {
    return (
      <ContentLoader
        loading={this.state.loading}
        content={() => this.renderForm()}
      />
    );
  }
}
