import React, { Component } from 'react';
import { updateTracker } from 'services/trackers';
import { validateSettings } from 'components/Forms/Settings/utils';
import FormSettings from 'components/Forms/Settings';
import BoxTitle from 'components/Box/Title';
import BoxButton from 'components/Box/Button';
import { TrackerContext } from '../context';
import NewFormSettings from 'components/Forms/Settings/indexx';

interface State {
    loading: boolean;
    settings: {};
}

export default class TrackerSettings extends Component<{}, State> {
    constructor(props: {}, context: any) {
        super(props);
        this.state = { loading: false, settings: JSON.parse(JSON.stringify(context.tracker.settings)) };
    }

    update = (settings: any) => this.setState({ settings })

    updateSettings = () => {
        const settings = Object.assign(
            {},
            this.context.tracker.settings,
            this.state.settings
        );
        const update = { settings };
        const tracker = Object.assign({}, this.context.tracker, update );

        this.setState({ loading : true });

        updateTracker({ id : this.context.tracker._id, update})
        .then(() => {
            this.context.updateTracker(tracker);
            this.setState({ loading: false });
        });
    }

    isThereAnUpdate = () => {
        return JSON.stringify(this.state.settings) !== JSON.stringify(this.context.tracker.settings);
    }

    render() {
        const validation = validateSettings(this.state.settings);

        const showSubmitButton = this.isThereAnUpdate() && validation.valid;

        return <div className="tracker-settings bc-wrapper">
          <BoxTitle text="Settings" />

          <NewFormSettings className='bc-stretch'
                    data={{
                        readOnly: false,
                        settings: this.state.settings,
                        validation
                    }}
                    update={this.update} />

          <div className="settings-btn-wrapper bc-wrapper">
              <BoxButton
                  category="Tracker Settings"
                  action="Click Update Settings"
                  label={this.context.tracker._id}
                  disabled={!showSubmitButton || this.state.loading}
                  onClick={this.updateSettings}
                  icon={this.state.loading ? "sync" : "save"}
                  text={this.state.loading ? "" : "Save"}
              />
          </div>
        </div>;
    }
}

TrackerSettings.contextType = TrackerContext;