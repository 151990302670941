import React, { Component } from 'react';
import BoxTitle from 'components/Box/Title';
import ContentLoader from 'components/Loading/ContentLoader';
import Button from 'components/Inputs/Button';
import BoxOption from 'components/Box/Option';
import TrackableElement from 'components/Trackable/Element';
import Icon from 'components/Icon';
import { getTransports } from 'services/trackers';
import { reducer } from 'utils/functions';
import { TrackerContext } from '../context';
import './styles.scss';

type Props = {}

type State = {
  transports: Array<any>;
  loading: boolean;
  active: string;
}

const STATUS = {
  ongoing: 'ongoing',
  completed: 'completed'
};

export default class TrackerShipments extends Component<Props, State> {
  constructor(props: Props ) {
    super(props);
    this.state = {
      transports : [],
      loading: true,
      active: ""
    };
  }

  componentDidMount() {
    getTransports({ id : this.context.tracker._id })
      .then(transports => this.setState({ transports, loading: false}));
  }

  showTransport = (transport: any) => {
    this.setState({ active : transport._id });
    this.context.updateMeasurements(transport.measurements);
  }

  renderTransport = (transport: any, index: number) => {
    const shipment = transport.shipment,
          current = this.state.active === transport._id;

    return <TrackableElement key={`TrackerShipment_${index}`}
      category="Tracker History"
      action="Click View Transport"
      onClick={() => this.showTransport(transport)}
      >
        <BoxOption selected={current}>
          <div className='history-shipment-info'>
            <h3 className="history-shipment-title">{shipment.name}</h3>
            <div className="history-shipment-field"><b>{shipment.origin.label}</b> to <b>{shipment.destination.label}</b></div>
            <Button
                category="Tracker History"
                action="Go To Shipment"
                label={shipment._id}
                styles={shipment.status === STATUS.ongoing ? 'solid' : 'outline'}
                className="history-shipment-button"
                to={`/shipments/${shipment._id}`}>
                <Icon name="search" size={20} /> View
              </Button>
          </div>
        </BoxOption>
      </TrackableElement>;
  }

  renderContent = () => {
    if(this.state.transports.length) {
      const [ongoing, completed] = reducer(this.state.transports, (transport: any) =>  transport.shipment.status === STATUS.ongoing);

      return (<>
        { ongoing.length > 0 &&
          <div className="shipments-container">
            {ongoing.map(this.renderTransport)}
          </div>
        }
        {
          completed.length > 0 && <div className="shipments-container">
          <h3 className="shipments-container__title">{completed.length} completed</h3>
            {completed.map(this.renderTransport)}
          </div>
        }
        </>);
    }

    return <h3>No shipments done</h3>;
  }

  render() {
    return <div className="tracker-shipments">
      <BoxTitle text="Shipments" />

      <div className="history-shipments">
        <ContentLoader loading={this.state.loading} content={this.renderContent} />
      </div>
    </div>;
  }
}

TrackerShipments.contextType = TrackerContext;